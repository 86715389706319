$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.report-location-container {
  .report-location-check {
    color: config.$primary-color;
    font-size: config.$font-subHeader;
    cursor: pointer;
    &:hover {
      background-color: config.$primary-color;
      color: config.$w-color;
      border-radius: 50%;
    }
  }
  .report-location-close {
    color: #ff0000;
    margin-left: 16%;
    font-size: config.$font-subHeader;
    &:hover {
      background-color: #ff0000;
      color: config.$w-color;
      border-radius: 50%;
    }
  }
}

.report-each-toilet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 16px;
    font-weight: 600;
    color: #0b79d9;
  }
}
