$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@include Responsive(XL) {
  .rechargeCom-phone-container {
    .rechargeCom-phone {
      margin-bottom: 4px;
    }
  }
  .ant-card-head-title {
    color: config.$primary-color;
    font-size: config.$font-header;
  }
  .rechargeCom-infor-price-input {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 40%;
  }
  .rechargeCom-infor-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: config.$font-subHeader;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 16px;
    
    .rechargeCom-infor-phone-container {
      width: 40%;
      .rechargeCom-infor-phone {
        font-size: config.$font-header;
        font-weight: 500;
        color: config.$primary-color;
      }
    }

    .rechargeCom-infor-name-container {
      width: 60%;
      .rechargeCom-infor-name {
        font-size: config.$font-header;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
  }
  .rechargeCom-infor-price-container {
    margin-top: 1%;
    font-size: config.$font-subHeader;
    .rechargeCom-infor-price-card-container {
      margin-top: 1%;
      width: 100%;
      .rechargeCom-infor-price-card {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;
        .rechargeCom-infor-price {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 7.5em;
          height: 3.5em;
          padding: 0.25em 0;
          margin-right: 0.5em;
          margin-bottom: 0.5em;
          border: 1px solid #d8e9f9;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-header;
          }
        }
        .rechargeCom-infor-price-active {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 7.5em;
          height: 3.5em;
          padding: 0.25em 0;
          margin-right: 0.5em;
          margin-bottom: 0.5em;
          border: 1px solid config.$secondary-color;
          background-color: config.$primary-color;
          color: config.$w-color;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          position: relative;
          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-header;
          }
          .rechargeCom-infor-icon {
            position: absolute;
            top: 4%;
            right: 2%;
            font-size: 20px;
            color: config.$w-color;
          }
        }
      }
    }
  }
  .rechargeCom-infor-btn {
    width: 96px;
    height: 40px;
    border: none;
    background-color: config.$primary-color;
    border-radius: 8px;
    color: config.$w-color;
    font-size: config.$font-subHeader;
    opacity: 80%;
    &:hover {
      opacity: 100;
      cursor: pointer;
    }
  }
}

@include Responsive(MD) {
  .rechargeCom-phone-container {
    margin-top: 16px;
    .rechargeCom-phone {
      margin-bottom: 4px;
    }
  }
  .rechargeCom-infor-price-input {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 60%;
  }
  .ant-card-head-title {
    color: config.$primary-color;
    font-size: config.$font-header;
  }
  .rechargeCom-infor-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: config.$font-subHeader;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 16px;
    .rechargeCom-infor-phone-container {
      width: 40%;
      line-height: 24px;
      .rechargeCom-infor-phone {
        font-size: config.$font-subHeader;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
    .rechargeCom-infor-name-container {
      width: 60%;
      line-height: 24px;
      .rechargeCom-infor-name {
        font-size: config.$font-subHeader;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
  }
  .rechargeCom-infor-price-container {
    margin-top: 2%;
    font-size: config.$font-subHeader;
    .rechargeCom-infor-price-card-container {
      margin-top: 2%;
      width: 100%;
      .rechargeCom-infor-price-card {
        display: flex;
        text-align: center;
        align-items: center;
        .rechargeCom-infor-price {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 6em;
          height: 3em;
          padding: 0.5em 0.25em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid #d8e9f9;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-subHeader;
          }
        }
        .rechargeCom-infor-price-active {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 6em;
          height: 3em;
          padding: 0.5em 0.25em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid config.$secondary-color;
          background-color: config.$primary-color;
          color: config.$w-color;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          position: relative;
          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-subHeader;
          }
          .rechargeCom-infor-icon {
            position: absolute;
            top: 4%;
            right: 2%;
            font-size: 14px;
            color: config.$w-color;
          }
        }
      }
    }
  }
  .rechargeCom-infor-btn {
    width: 80px;
    height: 32px;
    border: none;
    background-color: config.$primary-color;
    border-radius: 8px;
    color: config.$w-color;
    font-size: config.$font-content;
    opacity: 80%;
    &:hover {
      opacity: 100;
      cursor: pointer;
    }
  }
}

@include Responsive(SM) {
  .rechargeCom-phone-container {
    margin-top: 16px;
    .rechargeCom-phone {
      margin-bottom: 4px;
    }
  }
  .rechargeCom-infor-price-input {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 60%;
  }
  .ant-card-head-title {
    color: config.$primary-color;
    font-size: config.$font-subHeader;
  }
  .rechargeCom-infor-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: config.$font-content;
    border-bottom: 1px solid #f0f0f0;
    .rechargeCom-infor-phone-container {
      width: 40%;
      line-height: 24px;
      .rechargeCom-infor-phone {
        font-size: config.$font-content;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
    .rechargeCom-infor-name-container {
      width: 60%;
      line-height: 24px;
      .rechargeCom-infor-name {
        font-size: config.$font-content;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
  }
  .rechargeCom-infor-price-container {
    margin-top: 2%;
    font-size: config.$font-content;
    .rechargeCom-infor-price-card-container {
      margin-top: 2%;
      width: 100%;
      .rechargeCom-infor-price-card {
        display: flex;
        text-align: center;
        align-items: center;
        .rechargeCom-infor-price {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 5em;
          height: 3em;
          padding: 1.5em 0.5em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid #d8e9f9;
          font-size: 10px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-content;
          }
        }
        .rechargeCom-infor-price-active {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 5em;
          height: 3em;
          padding: 1.5em 0.5em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid #d8e9f9;
          font-size: 10px;
          border-radius: 8px;
          border: 1px solid config.$secondary-color;
          background-color: config.$primary-color;
          color: config.$w-color;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-content;
          }
          .rechargeCom-infor-icon {
            position: absolute;
            top: 4%;
            right: 2%;
            font-size: 14px;
            color: config.$w-color;
          }
        }
      }
    }
  }
  .rechargeCom-infor-btn {
    width: 80px;
    height: 32px;
    border: none;
    background-color: config.$primary-color;
    border-radius: 8px;
    color: config.$w-color;
    font-size: config.$font-content;
    opacity: 80%;
    &:hover {
      opacity: 100;
      cursor: pointer;
    }
  }
}

@include Responsive(XS) {
  .rechargeCom-phone-container {
    .rechargeCom-phone {
      margin-bottom: 4px;
    }
  }
  .rechargeCom-infor-price-input {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 100%;
  }
  .ant-card-head-title {
    color: config.$primary-color;
    font-size: config.$font-subHeader;
  }
  .rechargeCom-infor-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: config.$font-content;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 24px;
    .rechargeCom-infor-phone-container {
      width: 40%;
      line-height: 24px;
      .rechargeCom-infor-phone {
        font-size: config.$font-content;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
    .rechargeCom-infor-name-container {
      width: 60%;
      line-height: 24px;
      .rechargeCom-infor-name {
        font-size: config.$font-content;
        font-weight: 500;
        color: config.$primary-color;
      }
    }
  }
  .rechargeCom-infor-price-container {
    margin-top: 2%;
    font-size: config.$font-content;
    .rechargeCom-infor-price-card-container {
      margin-top: 2%;
      width: 100%;
      .rechargeCom-infor-price-card {
        display: flex;
        text-align: center;
        align-items: center;
        .rechargeCom-infor-price {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 5em;
          height: 3em;
          padding: 1.5em 0.5em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid #d8e9f9;
          font-size: 10px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-content;
          }
        }
        .rechargeCom-infor-price-active {
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
          width: 5em;
          height: 3em;
          padding: 1.5em 0.5em;
          font-size: 14px;
          margin-right: 0.75em;
          margin-bottom: 1em;
          border: 1px solid #d8e9f9;
          font-size: 10px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid config.$secondary-color;
          background-color: config.$primary-color;
          color: config.$w-color;
          cursor: pointer;
          position: relative;

          .rechargeCom-infor-turn {
            font-weight: 500;
            font-size: config.$font-content;
          }
          .rechargeCom-infor-icon {
            position: absolute;
            top: 4%;
            right: 2%;
            font-size: 14px;
            color: config.$w-color;
          }
        }
      }
    }
  }
  .rechargeCom-infor-btn {
    width: 80px;
    height: 32px;
    border: none;
    background-color: config.$primary-color;
    border-radius: 8px;
    color: config.$w-color;
    font-size: config.$font-content;
    opacity: 80%;
    &:hover {
      opacity: 100;
      cursor: pointer;
    }
  }
}
