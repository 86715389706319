$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../..//App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.create-new-toilet-unit-image-right-container {
  display: none;
  cursor: pointer;
}

.statistic-toilet-content-excel-btn {
  border: none;
  background-color: config.$primary-color;
  color: config.$w-color;
  padding: 8px 16px;
  border-radius: 8px;
}

@include Responsive(XL) {
  .manage-toilet-unit-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-popover-change-status {
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }

  .create-new-approve-toilet-unit-img-container-box {
    .create-new-approve-toilet-image-container {
      .create-new-approve-toilet-unit-left-image {
        height: 200px;
        width: 200px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }

  .create-new-infor-logo-empty-container {
    position: relative;
    .create-new-infor-logo-empty {
      width: 200px !important;
      height: 200px !important;
    }
    .create-new-infor-logo-empty-position {
      position: absolute;
      cursor: pointer;
      top: 0;
      .create-new-infor-logo-empty-position-in {
        height: 200px;
        width: 200px;
      }
      p {
        position: absolute;
        // top: 75%;
        // left: 25%;
        bottom: 0;
        color: config.$w-color;
        letter-spacing: 0.04em;
        opacity: 40%;
        background-color: config.$black-color;
        height: 70px;
        width: 200px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        text-align: center;
        padding-top: 30px;
        font-size: config.$font-subHeader;
      }
    }
  }

  //
  .create-new-approve-unit-container {
    margin-top: 40px;
    font-size: 16px;
    .create-new-approve-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 50%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 320px;
        }
      }
      .create-new-approve-toilet-image-container {
        margin-left: 6%;
        width: 320px;
        white-space: nowrap;
        .create-new-approve-toilet-unit-left-image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 100%;
        }
        .create-new-approve-toilet-image {
          width: 80px;
          height: 80px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      color: config.$primary-color;
      p {
        &:first-child {
          opacity: 80%;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
          width: 240px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 240px;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .create-new-toilet-unit-image-right {
    border-radius: 100%;
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
  }

  .manage-toilet-unit-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .manage-toilet-unit-table-content {
      margin-top: 32px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }

  .infor-each-toilet-unit-container {
    display: flex;
    width: 100%;
    .infor-each-toilet-unit-image {
      width: 160px;
      height: 160px;
      margin-right: 64px;
      margin-left: 32px;
      border-radius: 50%;
    }
    .infor-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          min-width: 120px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          min-width: 560px;
          max-width: 760px;
        }
      }
    }
  }

  .account-each-toilet-unit-container {
    .account-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 100px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
    }
  }

  .create-new-toilet-unit-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    padding: 24px;
    .create-new-toilet-unit-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-unit-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
    .create-new-toilet-unit-footer-container {
      margin-top: 40px;
    }

    .create-new-toilet-unit-input-box-container {
      margin-top: 40px;
      .create-new-toilet-unit-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin-bottom: 16px;
        p {
          width: 30%;
        }
        .create-new-toilet-unit-input {
          width: 70%;
        }
        .create-new-toilet-unit-image-container {
          width: 70%;
        }
      }
      .create-new-toilet-unit-btn {
        margin-top: 16px;
        margin-left: 15%;
      }
      .create-new-toilet-unit-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-account-manager-input-box-container {
      margin-top: 40px;
      .create-new-account-manager-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        margin-bottom: 16px;
        p {
          width: 40%;
        }
        .create-new-account-manager-input {
          width: 60%;
        }
      }
      .create-new-account-manager-btn {
        margin-top: 16px;
      }
      .create-new-account-manager-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-approve-toilet-unit-container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      font-size: 14px;
      .create-new-approve-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 160px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 320px;
          }
        }
        .create-new-approve-toilet-unit-image-container {
          margin-left: 6%;
          .create-new-approve-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
      .create-new-approve-config-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        align-items: center;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 240px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 300px;
          }
        }
        .create-new-approve-config-toilet-unit-image-container {
          margin-left: 8%;
          .create-new-approve-config-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

@include Responsive(MD) {
  .create-new-toilet-unit-image-right {
    border-radius: 100%;
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
  }
  .create-new-infor-logo-empty-container {
    position: relative;
    .create-new-infor-logo-empty {
      width: 200px !important;
      height: 200px !important;
    }
    .create-new-infor-logo-empty-position {
      position: absolute;
      cursor: pointer;
      top: 0;
      .create-new-infor-logo-empty-position-in {
        height: 200px;
        width: 200px;
      }
      p {
        position: absolute;
        // top: 75%;
        // left: 25%;
        bottom: 0;
        color: config.$w-color;
        letter-spacing: 0.04em;
        opacity: 40%;
        background-color: config.$black-color;
        height: 70px;
        width: 200px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        text-align: center;
        padding-top: 30px;
        font-size: config.$font-subHeader;
      }
    }
  }

  .create-new-approve-toilet-unit-img-container-box {
    .create-new-approve-toilet-image-container {
      .create-new-approve-toilet-unit-left-image {
        height: 160px;
        width: 160px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }
  //
  .create-new-approve-unit-container {
    margin-top: 40px;
    font-size: 14px;
    .create-new-approve-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      p {
        &:first-child {
          opacity: 50%;
          width: 120px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-toilet-image-container {
        margin-left: 6%;
        .create-new-approve-toilet-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          border-radius: 4px;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      align-items: center;

      color: config.$primary-color;
      p {
        &:first-child {
          opacity: 80%;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .manage-toilet-unit-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-popover-change-status {
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 24px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-unit-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }

  .infor-each-toilet-unit-container {
    display: flex;
    width: 100%;
    .infor-each-toilet-unit-image {
      width: 160px;
      height: 160px;
      margin-right: 64px;
      margin-left: 32px;
      border-radius: 50%;
    }
    .infor-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 300px;
        }
      }
      
      .infor-each-toilet-unit-image-container {
        margin-left: 8%;
        .infor-each-toilet-unit-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .account-each-toilet-unit-container {
    .account-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 100px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
    }
  }

  .create-new-toilet-unit-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    padding: 24px;
    .create-new-toilet-unit-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-unit-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
    .create-new-toilet-unit-footer-container {
      margin-top: 40px;
    }

    .create-new-toilet-unit-input-box-container {
      margin-top: 40px;
      .create-new-toilet-unit-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin-bottom: 16px;
        p {
          width: 30%;
        }
        .create-new-toilet-unit-input {
          width: 60%;
        }
        .create-new-toilet-unit-image-container {
          width: 60%;
        }
      }
      .create-new-toilet-unit-btn {
        margin-top: 16px;
        margin-left: 15%;
      }
      .create-new-toilet-unit-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-account-manager-input-box-container {
      margin-top: 40px;
      .create-new-account-manager-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        margin-bottom: 16px;
        p {
          width: 40%;
        }
        .create-new-account-manager-input {
          width: 60%;
        }
      }
      .create-new-account-manager-btn {
        margin-top: 16px;
      }
      .create-new-account-manager-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-approve-toilet-unit-container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      font-size: 14px;
      .create-new-approve-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 160px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 320px;
          }
        }
        .create-new-approve-toilet-unit-image-container {
          margin-left: 6%;
          .create-new-approve-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
      .create-new-approve-config-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        align-items: center;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 240px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 300px;
          }
        }
        .create-new-approve-config-toilet-unit-image-container {
          margin-left: 8%;
          .create-new-approve-config-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

@include Responsive(SM) {
  .create-new-toilet-unit-image-right {
    border-radius: 100%;
    width: 160px !important;
    height: 160px !important;
    object-fit: cover;
    left: 40%;
    position: absolute;
  }
  .create-new-infor-logo-empty-container {
    position: relative;
    margin-top: 16px;
    .create-new-infor-logo-empty {
      width: 160px !important;
      height: 160px !important;
      left: 40%;
      position: absolute;
    }
    .create-new-infor-logo-empty-position {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 40%;
      .create-new-infor-logo-empty-position-in {
        height: 160px;
        width: 160px;
      }
      p {
        position: absolute;
        bottom: 0;
        color: config.$w-color;
        letter-spacing: 0.04em;
        opacity: 40%;
        background-color: config.$black-color;
        height: 50px;
        width: 160px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        text-align: center;
        padding-top: 30px;
        font-size: config.$font-subHeader;
      }
    }
  }
  .create-new-approve-toilet-unit-img-container-box {
    .create-new-approve-toilet-image-container {
      margin-left: 8%;
      .create-new-approve-toilet-unit-left-image {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }
  //
  .create-new-approve-unit-container {
    margin-top: 24px;
    font-size: 14px;
    .create-new-approve-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      p {
        &:first-child {
          opacity: 50%;
          width: 120px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-toilet-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          border-radius: 4px;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      align-items: center;

      color: config.$primary-color;
      p {
        &:first-child {
          opacity: 80%;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .manage-toilet-unit-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-popover-change-status {
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 24px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-unit-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .infor-each-toilet-unit-container {
    display: flex;
    width: 100%;
    .infor-each-toilet-unit-image {
      width: 100px;
      height: 100px;
      margin-right: 32px;
      border-radius: 50%;
    }
    .infor-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 2%;
          font-weight: 500;
          width: 300px;
        }
      }
      .infor-each-toilet-unit-image-container {
        margin-left: 8%;
        .infor-each-toilet-unit-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .account-each-toilet-unit-container {
    .account-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 100px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
    }
  }
  .create-new-toilet-unit-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    padding: 24px;
    .create-new-toilet-unit-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-unit-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
    .create-new-toilet-unit-footer-container {
      margin-top: 40px;
    }

    .create-new-toilet-unit-input-box-container {
      margin-top: 40px;
      .create-new-toilet-unit-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        p {
          width: 30%;
        }
        .create-new-toilet-unit-input {
          width: 60%;
        }
        .create-new-toilet-unit-image-container {
          width: 60%;
        }
      }
      .create-new-toilet-unit-btn {
        margin-top: 16px;
        margin-left: 15%;
      }
      .create-new-toilet-unit-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-account-manager-input-box-container {
      margin-top: 40px;
      .create-new-account-manager-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        p {
          width: 40%;
        }
        .create-new-account-manager-input {
          width: 60%;
        }
      }
      .create-new-account-manager-btn {
        margin-top: 16px;
      }
      .create-new-account-manager-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-approve-toilet-unit-container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      font-size: 14px;
      .create-new-approve-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 160px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 320px;
          }
        }
        .create-new-approve-toilet-unit-image-container {
          margin-left: 6%;
          .create-new-approve-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
      .create-new-approve-config-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        align-items: center;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 240px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 300px;
          }
        }
        .create-new-approve-config-toilet-unit-image-container {
          margin-left: 8%;
          .create-new-approve-config-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

@include Responsive(XS) {
  .create-new-toilet-unit-image-right {
    border-radius: 100%;
    width: 120px !important;
    height: 120px !important;
    left: 32%;
    position: absolute;
    object-fit: cover;
  }
  .create-new-infor-logo-empty-container {
    position: relative;
    margin-top: 16px;
    .create-new-infor-logo-empty {
      width: 120px !important;
      height: 120px !important;
      left: 32%;
      position: absolute;
    }
    .create-new-infor-logo-empty-position {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 32%;
      .create-new-infor-logo-empty-position-in {
        height: 120px;
        width: 120px;
      }
      p {
        position: absolute;
        bottom: 0;
        color: config.$w-color;
        letter-spacing: 0.04em;
        opacity: 32%;
        background-color: config.$black-color;
        height: 40px;
        width: 120px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        text-align: center;
        padding-top: 24px;
        font-size: config.$font-content;
      }
    }
  }
  .create-new-toilet-input-right-container {
    height: 22vh !important;
  }
  .create-new-approve-toilet-unit-img-container-box {
    .create-new-approve-toilet-image-container {
      .create-new-approve-toilet-unit-left-image {
        height: 150px;
        width: 150px;
        object-fit: cover;
        border-radius: 100%;
        margin-bottom: 8%;
        margin-left: 16%;
      }
    }
  }
  //
  .create-new-approve-unit-container {
    font-size: 14px;
    .create-new-approve-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      p {
        &:first-child {
          opacity: 50%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
        }
      }
      .create-new-approve-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-toilet-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          border-radius: 4px;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 32px;
      align-items: center;

      color: config.$primary-color;
      p {
        &:first-child {
          opacity: 80%;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  .manage-toilet-unit-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-popover-change-status {
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-unit-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 24px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-unit-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .infor-each-toilet-unit-container {
    .infor-each-toilet-unit-image {
      width: 160px;
      height: 160px;
      margin-left: 25%;
      margin-top: 16px;
      margin-bottom: 16px;
      border-radius: 50%;
    }
    .infor-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 180px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 300px;
        }
      }
      .infor-each-toilet-unit-image-container {
        margin-left: 8%;
        .infor-each-toilet-unit-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  .account-each-toilet-unit-container {
    .account-each-toilet-unit {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      p {
        &:first-child {
          opacity: 80%;
          width: 100px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
    }
  }
  .create-new-toilet-unit-box-container {
    box-sizing: border-box;
    height: 98%;
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 24px;
    .create-new-toilet-unit-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-unit-step-container {
      width: 100%;
      margin: auto;
      margin-top: 24px;
      font-size: 12px !important;
    }
    .create-new-toilet-unit-footer-container {
      margin-top: 40px;
    }

    .create-new-toilet-unit-input-box-container {
      margin-top: 24px;
      .create-new-toilet-unit-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        p {
          width: 30%;
        }
        .create-new-toilet-unit-input {
          width: 60%;
        }
        .create-new-toilet-unit-image-container {
          width: 60%;
        }
      }
      .create-new-toilet-unit-btn {
        margin-top: 16px;
        margin-left: 15%;
      }
      .create-new-toilet-unit-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-account-manager-input-box-container {
      margin-top: 24px;
      .create-new-account-manager-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;
        p {
          width: 40%;
        }
        .create-new-account-manager-input {
          width: 60%;
        }
      }
      .create-new-account-manager-btn {
        margin-top: 16px;
      }
      .create-new-account-manager-save-btn {
        margin-left: 17%;
        margin-top: 8px;
      }
    }
    .create-new-approve-toilet-unit-container {
      margin-top: 24px;
      font-size: 14px;
      .create-new-approve-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        width: 100%;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 50%;
          }
          &:nth-child(2) {
            font-weight: 500;
            width: 50%;
          }
        }
        .create-new-approve-toilet-unit-image-container {
          .create-new-approve-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
      .create-new-approve-config-toilet-unit {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        align-items: center;
        color: config.$primary-color;
        p {
          &:first-child {
            opacity: 80%;
            width: 240px;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 300px;
          }
        }
        .create-new-approve-config-toilet-unit-image-container {
          margin-left: 8%;
          .create-new-approve-config-toilet-unit-image {
            width: 80px;
            height: 80px;
            margin-right: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
