$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@include Responsive(XL) {
  .menu-container {
    background-color: config.$w-color;
    .menu-logo {
      width: 64px;
      height: 64px;
      margin-bottom: 16px;
      margin-left: 8%;
      margin-top: 8%;
    }
    height: 100%;
    width: 16%;
    top: 0;
    position: fixed;
    margin-top: auto;
    margin-bottom: auto;
    border-right: 1px solid config.$secondary-color;
    .menu-header-name-container {
      margin-left: 24px;
      p {
        font-size: config.$font-content;
        margin-bottom: 8px;
        margin-top: 0;
        color: config.$primary-color;
      }
      h2 {
        font-size: config.$font-subHeader;
        margin-top: 0;
        color: config.$primary-color;
        width: 80%;
        line-height: 24px;
      }
    }
    .menu-content-container {
      margin-left: 24px;
      margin-top: 32px;
      .menu-content-link-active {
        opacity: 100%;
        border-right: 4px solid config.$primary-color;
      }
      a {
        display: flex;
        height: 32px;
        line-height: 32px;
        align-items: center;
        font-weight: 500;
        opacity: 40%;
        span {
          margin-left: 16px;
          font-size: config.$font-subHeader;
        }
      }
    }
    .menu-log-out {
      bottom: 4%;
      position: absolute;
      margin-left: 24px;
      opacity: 100%;
      span {
        margin-left: 16px;
        font-weight: 500;
        font-size: config.$font-subHeader;
      }
    }
  }
}

@include Responsive(MD) {
  .menu-container {
    top: 0;
    .menu-logo {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      margin-left: 8%;
      margin-top: 8%;
    }
    height: 100%;
    width: 16%;
    position: fixed;
    background-color: config.$w-color;
    margin-top: auto;
    margin-bottom: auto;
    border-right: 1px solid config.$secondary-color;
    .menu-header-name-container {
      margin-left: 16px;
      p {
        font-size: config.$font-content;
        margin-bottom: 8px;
        margin-top: 0;
        color: config.$primary-color;
      }
      h2 {
        font-size: config.$font-subHeader;
        margin-top: 0;
        color: config.$primary-color;
      }
    }
    .menu-content-container {
      margin-left: 16px;
      margin-top: 24px;
      .menu-content-link-active {
        opacity: 100%;
        border-right: 4px solid config.$primary-color;
      }
      a {
        display: flex;
        height: 24px;
        line-height: 24px;
        align-items: center;
        font-weight: 500;
        opacity: 40%;
        span {
          margin-left: 8px;
          font-size: config.$font-content;
        }
      }
    }
    .menu-log-out {
      bottom: 4%;
      position: absolute;
      margin-left: 16px;
      opacity: 100%;
      span {
        margin-left: 16px;
        font-size: config.$font-content;
        font-weight: 500;
      }
    }
  }
}

@include Responsive(SM) {
  .menu-container {
    .menu-logo {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      margin-left: 4%;
      margin-top: 8%;
    }
    height: 100%;
    top: 0;
    width: 16%;
    position: fixed;
    margin-top: auto;
    margin-bottom: auto;
    border-right: 1px solid config.$secondary-color;
    background-color: config.$w-color;
    .menu-header-name-container {
      margin-left: 8px;
      p {
        font-size: config.$font-content;
        margin-bottom: 8px;
        margin-top: 0;
        color: config.$primary-color;
      }
      h2 {
        font-size: config.$font-content;
        margin-top: 0;
        color: config.$primary-color;
      }
    }
    .menu-content-container {
      margin-left: 8px;
      margin-top: 24px;
      .menu-content-link-active {
        opacity: 100%;
        border-right: 4px solid config.$primary-color;
      }
      a {
        display: flex;
        height: 24px;
        line-height: 24px;
        align-items: center;
        font-weight: 500;
        opacity: 40%;
        span {
          margin-left: 4px;
          font-size: config.$font-content;
          width: 72%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .menu-log-out {
      bottom: 4%;
      position: absolute;
      margin-left: 8px;
      opacity: 100%;
      span {
        margin-left: 4px;
        font-weight: 500;
        font-size: config.$font-content;
      }
    }
  }
}

@include Responsive(XS) {
  .menu-container {
    .menu-logo {
      width: 40px;
      height: 40px;
    }
  }
  .menu-header-name-container {
    p {
      font-size: config.$font-content;
      margin-bottom: 8px;
      margin-top: 0;
      color: config.$primary-color;
    }
    h2 {
      font-size: config.$font-subHeader;
      margin-top: 0;
      color: config.$primary-color;
    }
  }
  .menu-content-container {
    margin-top: 32px;
    .menu-content-link-active {
      opacity: 100%;
    }
    a {
      display: flex;
      height: 32px;
      line-height: 32px;
      align-items: center;
      font-weight: 500;
      opacity: 40%;
      span {
        margin-left: 16px;
        font-size: config.$font-subHeader;
      }
    }
  }
  .menu-log-out {
    bottom: 4%;
    position: absolute;
    margin-left: 24px;
    opacity: 100%;
    span {
      margin-left: 16px;
      font-weight: 500;
      font-size: config.$font-subHeader;
    }
  }
  .menu-container-icon {
    margin-left: 24%;
    font-size: 16px;
    padding: 8px;
    border: 4px solid config.$secondary-color;
    border-radius: 8px;
    background-color: config.$secondary-color;
    cursor: pointer;
  }
}
