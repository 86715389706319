$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.react-auto-scroll__option {
  display: none;
}

.script-select-container {
  margin-top: 2%;
  .script-select {
    width: 100%;
  }

  .script-input-number-container {
    border: 1px solid #d9d9d9;
    padding: 4%;
    border-radius: 6px;
    max-width: 100%;
    h4 {
      margin-bottom: 2%;
    }
    .script-input-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1%;
      p {
        min-width: 160px;
      }
    }
  }

  .script-input-guest-number-container {
    border: 1px solid #d9d9d9;
    padding: 4%;
    border-radius: 6px;
    max-width: 100%;
    h4 {
      margin-bottom: 2%;
    }
    .script-input-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1%;
      p {
        min-width: 160px;
      }
    }
  }

  .script-button {
    display: flex;
    margin-top: 1%;
    align-items: center;
    justify-content: space-evenly;
    min-width: 120px;
    padding: 0 0;
  }
  .script-input {
    width: 80px;
    margin-right: 1%;
    text-align: right;
  }
}

.script-input-output-container-medium-screen {
  min-height: 520px;
  background-color: config.$back-color;
  border-radius: 16px;
  margin-top: 2%;
  padding: 2%;
  .script-input-container {
    font-weight: 600;
    font-size: config.$font-subHeader;
    padding-top: 1%;
    margin-left: 1%;

    .script-output-result-container {
      min-height: 56vh;
      margin-top: 2vh;
      background-color: config.$w-color;
      border-radius: 16px;

      .script-output-result-header {
        padding: 4%;
        margin: 4%;
        padding-bottom: 2%;
        font-weight: 600;
        font-size: config.$font-subHeader;
        border-bottom: 2px solid config.$back-color;
      }
      .script-output-result-content {
        min-height: 44vh;
        background-color: config.$back-color;
        border-radius: 16px;
        margin: 0 4%;
        padding: 4%;
        font-size: 14px;
        line-height: 1.5em;
        font-weight: 500;
      }
    }
  }
  .script-output-container {
    background-color: config.$w-color;
    border-radius: 16px;
    padding: 2%;
    .script-result-header {
      padding-bottom: 2%;
      font-weight: 600;
      font-size: config.$font-subHeader;
      border-bottom: 2px solid config.$back-color;
    }
    .script-result {
      height: 60vh;
      overflow-y: scroll;
      margin-top: 2%;
      background-color: config.$back-color;
      border-radius: 16px;
      padding: 2%;
      line-height: 2em;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .script-result::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .script-result {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.script-input-output-container {
  min-height: 300px;
  max-height: 700px;
  background-color: config.$back-color;
  //   border: 1px solid #d9d9d9;
  border-radius: 16px;
  margin-top: 2%;
  padding: 2%;
  .script-input-container {
    font-weight: 600;
    font-size: config.$font-subHeader;
    padding-top: 1%;
    margin-left: 1%;

    .script-output-result-container {
      min-height: 47vh;
      margin-top: 2vh;
      background-color: config.$w-color;
      border-radius: 16px;
      
      
      .script-output-result-header {
        padding: 4%;
        margin: 4%;
        padding-bottom: 2%;
        font-weight: 600;
        font-size: config.$font-subHeader;
        border-bottom: 2px solid config.$back-color;
      }
      .script-output-result-content::-webkit-scrollbar {
        display: none;
      }
      .script-output-result-content {
        min-height: 35vh;
        max-height: 35vh;
        background-color: config.$back-color;
        border-radius: 16px;
        margin: 0 4%;
        padding: 4%;
        font-size: 14px;
        line-height: 1.5em;
        font-weight: 400;
        overflow-y: scroll;
      }
    }
  }
  .script-output-container {
    background-color: config.$w-color;
    border-radius: 16px;
    padding: 2%;
    .script-result-header {
      padding-bottom: 2%;
      font-weight: 600;
      font-size: config.$font-subHeader;
      border-bottom: 2px solid config.$back-color;
    }
    .script-result {
      height: 52vh;
      overflow-y: scroll;
      margin-top: 2%;
      background-color: config.$back-color;
      border-radius: 16px;
      padding: 2%;
      line-height: 2em;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .script-result::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .script-result {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
