@use "./App.scss" as config;

*,body {
  margin: 0;
  font-family: config.$font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: config.$font-stack;
}
