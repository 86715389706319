$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.statistic-advice-icon {
  font-size: 16px;
  &:hover {
    opacity: 80%;
  }
}

.ant-popover-inner {
  max-width: 280px;
  .ant-popconfirm-description {
    margin-inline-start: 8px !important;
  }
}

.ant-popconfirm-buttons {
  margin-top: 4%;
  button {
    height: 28px !important;
  }
}

@include Responsive(XL) {
  .statistic-container {
    .ant-table-content {
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
    }
    .statistic-table-content-container {
      height: 98%;
      box-sizing: border-box;
      margin-left: 40px;
      .statistic-each-toilet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
        padding: 1% 0 1% 1%;
        border-radius: 10px;
        background-color: config.$w-color;
        .statistic-each-toilet-number-container {
          border-right: 1px solid config.$secondary-color;
          width: 50%;
          padding-right: 2%;
          &:nth-child(2) {
            padding-left: 32px;
            border: none;
          }
          .statistic-each-toilet-number {
            font-size: 14px;
          }
          .statistic-each-toilet-number-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .statistic-each-toilet-count-number {
              font-size: 24px;
              font-weight: 500;
            }
            .statistic-each-toilet-count-icon {
              font-size: 32px;
              box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
              border-radius: 12px;
              padding: 2%;
              color: config.$primary-color;
            }
          }
          .statistic-each-toilet-rate {
            opacity: 50%;
            font-size: 10px;
            margin-top: 2%;
            .statistic-each-toilet-icon {
              color: config.$primary-color;
            }
          }
        }
      }
    }
    .statistic-toilet-content-excel-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .statistic-toilet-content-excel-btn {
        border: none;
        background-color: config.$primary-color;
        color: config.$w-color;
        padding: 8px 16px;
        border-radius: 16px;
        margin-left: 16px;
      }
    }
    .statistic-toilet-content {
      margin-top: 1.5%;
      border: 1px solid config.$secondary-color;
      padding: 0.25% 1%;
      border-radius: 10px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        font-size: 12px !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 1%;
          font-size: 12px !important;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
        font-size: 12px !important;
      }
    }
    .statistic-toilet-popover-see-infor {
      padding-bottom: 8px;
      border-bottom: 1px solid config.$secondary-color;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: config.$primary-color;
      }
    }
  }
}

@include Responsive(MD) {
  .statistic-container {
    .ant-table-content {
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
    }
    .statistic-table-content-container {
      height: 98%;
      box-sizing: border-box;
      margin-left: 40px;
      .statistic-each-toilet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
        padding: 1% 0 1% 1%;
        border-radius: 10px;
        background-color: config.$w-color;
        .statistic-each-toilet-number-container {
          border-right: 1px solid config.$secondary-color;
          width: 50%;
          padding-right: 2%;
          &:nth-child(2) {
            padding-left: 32px;
            border: none;
          }
          .statistic-each-toilet-number {
            font-size: 14px;
          }
          .statistic-each-toilet-number-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .statistic-each-toilet-count-number {
              font-size: 24px;
              font-weight: 500;
            }
            .statistic-each-toilet-count-icon {
              font-size: 32px;
              box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
              border-radius: 12px;
              padding: 2%;
              color: config.$primary-color;
            }
          }
          .statistic-each-toilet-rate {
            opacity: 50%;
            font-size: 10px;
            margin-top: 2%;
            .statistic-each-toilet-icon {
              color: config.$primary-color;
            }
          }
        }
      }
    }
    .statistic-toilet-content-excel-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .statistic-toilet-content-excel-btn {
        border: none;
        background-color: config.$primary-color;
        color: config.$w-color;
        padding: 8px 16px;
        border-radius: 16px;
        margin-left: 16px;
      }
    }
    .statistic-toilet-content {
      margin-top: 1.5%;
      border: 1px solid config.$secondary-color;
      padding: 0.25% 1%;
      border-radius: 10px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        font-size: 12px !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 1%;
          font-size: 12px !important;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
        font-size: 12px !important;
      }
    }
    .statistic-toilet-popover-see-infor {
      padding-bottom: 8px;
      border-bottom: 1px solid config.$secondary-color;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: config.$primary-color;
      }
    }
  }
}

@include Responsive(SM) {
  .statistic-container {
    .ant-table-content {
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
    }
    .statistic-table-content-container {
      height: 98%;
      box-sizing: border-box;
      margin-left: 40px;
      .statistic-each-toilet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
        padding: 1% 0 1% 1%;
        border-radius: 10px;
        background-color: config.$w-color;
        .statistic-each-toilet-number-container {
          border-right: 1px solid config.$secondary-color;
          width: 50%;
          padding-right: 2%;
          &:nth-child(2) {
            padding-left: 32px;
            border: none;
          }
          .statistic-each-toilet-number {
            font-size: 14px;
          }
          .statistic-each-toilet-number-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .statistic-each-toilet-count-number {
              font-size: 24px;
              font-weight: 500;
            }
            .statistic-each-toilet-count-icon {
              font-size: 32px;
              box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
              border-radius: 12px;
              padding: 2%;
              color: config.$primary-color;
            }
          }
          .statistic-each-toilet-rate {
            opacity: 50%;
            font-size: 10px;
            margin-top: 2%;
            .statistic-each-toilet-icon {
              color: config.$primary-color;
            }
          }
        }
      }
    }
    .statistic-toilet-content-excel-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .statistic-toilet-content-excel-btn {
        border: none;
        background-color: config.$primary-color;
        color: config.$w-color;
        padding: 8px 16px;
        border-radius: 16px;
        margin-left: 16px;
      }
    }
    .statistic-toilet-content {
      margin-top: 1.5%;
      border: 1px solid config.$secondary-color;
      padding: 0.25% 1%;
      border-radius: 10px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        font-size: 12px !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 1%;
          font-size: 12px !important;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
        font-size: 12px !important;
      }
    }
    .statistic-toilet-popover-see-infor {
      padding-bottom: 8px;
      border-bottom: 1px solid config.$secondary-color;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: config.$primary-color;
      }
    }
  }
}

@include Responsive(XS) {
  .statistic-container {
    .ant-table-content {
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
    }
    .statistic-table-content-container {
      height: 98%;
      box-sizing: border-box;
      .statistic-each-toilet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
        padding: 2% 0 2% 2%;
        border-radius: 10px;
        background-color: config.$w-color;
        .statistic-each-toilet-number-container {
          border-right: 1px solid config.$secondary-color;
          width: 50%;
          padding-right: 2%;
          &:nth-child(2) {
            padding-left: 16px;
            border: none;
          }
          .statistic-each-toilet-number {
            font-size: 12px;
          }
          .statistic-each-toilet-number-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .statistic-each-toilet-count-number {
              font-size: 14px;
              font-weight: 500;
            }
            .statistic-each-toilet-count-icon {
              font-size: 16px;
              box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
              border-radius: 12px;
              padding: 2%;
              color: config.$primary-color;
            }
          }
          .statistic-each-toilet-rate {
            opacity: 50%;
            font-size: 10px;
            margin-top: 2%;
            .statistic-each-toilet-icon {
              color: config.$primary-color;
            }
          }
        }
      }
    }
    .statistic-toilet-content-excel-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .statistic-toilet-content-excel-btn {
        border: none;
        background-color: config.$primary-color;
        color: config.$w-color;
        padding: 8px 8px;
        border-radius: 16px;
        font-size: 10px;
        margin-left: 16px;
      }
    }
    .statistic-table-header-date {
      input {
        font-size: 10px !important;
      }
    }
    .statistic-toilet-content {
      margin-top: 4%;
      border: 1px solid config.$secondary-color;
      padding: 0.25% 1%;
      border-radius: 10px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        font-size: 12px !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 1%;
          font-size: 12px !important;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
        font-size: 12px !important;
      }
    }
    .statistic-toilet-popover-see-infor {
      padding-bottom: 8px;
      border-bottom: 1px solid config.$secondary-color;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: config.$primary-color;
      }
    }
  }
}