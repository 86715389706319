@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$font-stack: "Roboto", sans-serif;
$primary-color: #0b79d9;
$secondary-color: #d8e9f9;
$w-color: #fff;
$black-color: #000;
$back-color: #f7f9fc;


$font-header: 20px;
$font-subHeader: 16px;
$font-content-large: 14px;
$font-content: 12px;

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  background: linear-gradient(
    rgb(53, 53, 255) 0%,
    rgb(91, 187, 255) 71%
  );
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    rgba(53, 53, 255, 0.8) 0%,
    rgba(91, 187, 255, 0.8) 71%
  );
}

html,
body {
  font-family: $font-stack;
  // background: linear-gradient(
  //   to bottom,
  //   #FFF,
  //   #EBFAFF
  // );
  // background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(255, 255, 255), rgb(250, 255, 255), rgb(230, 246, 253));
  background-color: $back-color;
  box-sizing: border-box;
  min-height: 100%;
}

.container {
  margin-top: 16px;
  box-sizing: border-box;
  height: 100%;
}

.ant-table-row {
  height: 43.650px !important;
}

.ant-table {
  font-size: 14px !important;
  .ant-table-container {
    border: none !important;
  }
}
table {
  border: 1px solid #deedfaa4;
  border-radius: 8px !important;
}
.table {
  margin-top: 16px;
  ul {
    justify-content: center;
    .ant-pagination-total-text {
      position: absolute;
      left: 0;
    }
  }
  .ant-badge-status-text {
    font-size: 12px;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #edf5fc;
      border-color: $w-color !important;
    }
  }
  .ant-table-content {
    border-radius: 8px;
    border: 4px solid $w-color;
  }
  .ant-table-small {
    border: 1px solid #deedfaa4;
    border-radius: 8px;
    font-size: 12px !important;
  }
  .manage-toilet-unit-btn-more {
    border: none;
    outline: none;
    box-shadow: none;
    height: 22px;
  }

  .table-config-td {
    border: none !important;
    border-bottom: 1px solid #deedfaa4 !important;
    background-color: #F7F9FC !important;
  }

  .ant-table-tbody tr {
    td {
      border: none !important;
      border-bottom: 1px solid #deedfaa4 !important;
      // padding: 0 !important;
    }
    
    &:last-child td {
      border: none !important;
    }
  }
}

input:disabled {
  background-color: $secondary-color !important;
  color: #0000006e !important;
}

.ant-modal-title {
  color: $primary-color !important;
  font-size: $font-header !important;
}

.ant-modal-close {
  &:hover {
    background-color: white !important;
  }
}

.ant-pagination-options {
  display: none !important;
}
