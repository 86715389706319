$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../..//App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.rating-each-toilet-more-btn {
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: config.$primary-color;
  }
}

.image-gallery-svg {
  height: 32px !important;
  width: 24px !important;
}

.image-gallery-fullscreen-button {
  display: none;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery-thumbnail-inner,
.image-gallery-thumbnail-image {
  height: 64px;
}

.image-gallery-image {
  height: 232px !important;
  object-fit: cover !important;
}

.infor-each-toilet-upload-image {
  display: none;
}

.manage-toilet-container {
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
  background-color: config.$w-color;
}

.manage-toilet-suggestion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 64%;
  font-size: 14px !important;
}

.manage-toilet-suggestion-each-quarter-header {
  font-size: 14px !important;
  border-top: 2px solid #d6d6d648;
  margin-top: 4px;
  padding-top: 4px;
  margin-bottom: 4px;
}

.manage-toilet-suggestion-each-quarter {
  font-size: 14px !important;
}

@include Responsive(XL) {
  .manage-toilet-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .manage-toilet-table-content {
      margin-top: 32px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .manage-toilet-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-popover-change-status {
    padding-top: 8px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .report-each-toilet-container {
    height: 98%;
    box-sizing: border-box;
    .report-each-toilet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid config.$secondary-color;
      padding: 1% 0 1% 1%;
      border-radius: 10px;
      .report-each-toilet-number-container {
        border-right: 1px solid config.$secondary-color;
        width: 50%;
        padding-right: 2%;
        &:nth-child(2) {
          padding-left: 32px;
          border: none;
        }
        .report-each-toilet-number {
          font-size: 14px;
        }
        .report-each-toilet-number-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .report-each-toilet-count-number {
            font-size: 24px;
            font-weight: 500;
          }
          .report-each-toilet-count-icon {
            font-size: 32px;
            box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
            border-radius: 12px;
            padding: 2%;
            color: config.$primary-color;
          }
        }
        .report-each-toilet-rate {
          opacity: 50%;
          font-size: 10px;
          margin-top: 2%;
          .report-each-toilet-icon {
            color: config.$primary-color;
          }
        }
      }
    }
  }
  .report-each-toilet-content-excel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .report-each-toilet-content-excel-btn {
      border: none;
      background-color: config.$primary-color;
      color: config.$w-color;
      padding: 8px 16px;
      border-radius: 16px;
      margin-left: 16px;
    }
  }
  .report-each-toilet-content {
    margin-top: 1.5%;
    border: 1px solid config.$secondary-color;
    padding: 0.25% 1%;
    border-radius: 10px;
    th {
      background-color: config.$w-color !important;
    }
    ul {
      justify-content: center;
      font-size: 12px !important;
      .ant-pagination-total-text {
        position: absolute;
        left: 1%;
        font-size: 12px !important;
      }
    }
    .ant-table-cell {
      background-color: config.$w-color !important;
      font-size: 12px !important;
    }
  }
  .account-each-toilet-container {
    .account-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 40%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 50%;
        }
      }
      .account-each-toilet-image-container {
        margin-left: 8%;
        .account-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .account-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      margin-bottom: 16px;
      p {
        width: 40%;
      }
      .account-each-toilet-input {
        width: 56%;
      }
    }
    .account-each-toilet-btn {
      margin-top: 8px;
    }
    .account-each-toilet-input-btn-container {
      margin-left: 17.5%;
      .account-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
  }

  .infor-each-toilet-container {
    .infor-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 16%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .infor-each-toilet-image-container {
        margin-left: 8%;
        width: 100%;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .infor-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      margin-bottom: 16px;
      .infor-each-toilet-input {
        width: 60%;
        display: inline-block;
      }
      .infor-each-toilet-image-container {
        width: 60%;
        display: flex;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
      .infor-each-toilet-add-image {
        display: inline-flex;
        background-color: config.$secondary-color;
        width: 80px;
        height: 80px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px dashed config.$black-color;
      }
    }
    .infor-each-toilet-input-btn-container {
      margin-left: 20%;
      .infor-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
    .infor-each-toilet-btn {
      margin-top: 16px;
    }
  }

  .config-each-toilet-container {
    .config-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      width: 50%;
      p {
        &:first-child {
          opacity: 80%;
          width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 500px;
        }
      }
      .config-each-toilet-image-container {
        margin-left: 8%;
        .config-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .config-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
        }
      }
      .config-each-toilet-input {
        width: 24%;
      }
      .config-each-toilet-input-tag {
        width: 400px;
      }
      .config-each-toilet-input-number {
        width: 80px;
      }
    }
    .config-each-toilet-btn {
      margin-top: 16px;
    }
    .config-each-toilet-input-btn-container {
      margin-left: 40%;
      margin-top: 4%;
      .config-each-toilet-save-btn {
        margin-right: 8px;
      }
    }

    .config-each-toilet-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-toilet-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .create-new-toilet-footer-container {
      margin-top: 40px;
    }
    .create-new-toilet-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
  }
  .create-new-toilet-input-box-container {
    margin-top: 40px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    .create-new-toilet-input-left-container {
      width: 50%;
      input[type="file"] {
        padding: 0;
        border: none;
      }
      input[type="file"]::file-selector-button {
        border: none;
        background: config.$primary-color;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }

      input[type="file"]::file-selector-button:hover {
        background: #0d45a5;
      }
    }
    .create-new-toilet-input-right-container {
      width: 40%;
      .create-new-toilet-unit-logo-container {
      }
      .create-new-toilet-unit-logo {
        svg {
          width: 50px;
          height: 50px;
        }
        p {
          margin-top: 16%;
        }
      }
    }
    .create-new-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 30%;
      }
      .create-new-toilet-input {
        width: 70%;
      }
      .create-new-toilet-image-container {
        width: 70%;
        white-space: nowrap;
        .create-new-toilet-image-appear-container {
          position: relative;
          .create-new-toilet-image-icon {
            position: absolute;
            right: 6%;
            top: 0%;
            color: #ff0000c8;
            padding: 4%;
            opacity: 100%;
            padding: 2%;
            background-color: config.$w-color;
            border-radius: 50%;
            font-size: 16px;
            &:hover {
              color: #ff0000;
            }
          }
        }
        .create-new-toilet-image {
          width: 64px;
          height: 64px;
          object-fit: cover;
          padding: 0.4em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
          opacity: 100%;
        }
      }
    }
    .create-new-toilet-btn {
      margin-top: 16px;
      margin-left: 15%;
    }
    .create-new-toilet-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  .create-new-config-input-box-container {
    margin-top: 40px;
    .create-new-config-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
        }
      }
      .create-new-config-input {
        width: 30%;
      }
      .create-new-config-input-tag {
        width: 400px;
      }
      .create-new-config-input-number {
        width: 80px;
      }
    }
    .create-new-config-btn {
      margin-top: 16px;
      margin-left: 25%;
    }
    .create-new-config-save-btn {
      margin-left: 23%;
      margin-top: 8px;
    }
    .create-new-config-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-account-input-box-container {
    margin-top: 40px;
    .create-new-account-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      margin-bottom: 16px;
      p {
        width: 30%;
      }
      .create-new-account-input {
        width: 60%;
      }
    }
    .create-new-account-btn {
      margin-top: 16px;
    }
    .create-new-account-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }

  //
  .create-new-approve-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    font-size: 16px;
    .create-new-approve-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 16px;
          font-weight: 500;
          width: 280px;
        }
      }
      .create-new-approve-toilet-image-container {
        width: 320px;
        white-space: nowrap;
        margin-left: 16px;
        margin-right: 16px;
        .create-new-approve-toilet-image {
          width: 80px;
          height: 80px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
          width: 200px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 280px;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }

  //rating
  .rating-each-toilet-filter-container {
    .rating-each-toilet-filter-header-text {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-header-text-star {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      margin-top: 4%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-checkbox {
      margin-bottom: 1%;
    }
    .rating-each-toilet-filter-btn-container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 8%;
    }
  }

  .rating-each-toilet-container {
    .rating-each-toilet-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: config.$font-subHeader;
        font-weight: 600;
        color: config.$primary-color;
      }
    }
    .rating-each-toilet-status-solve-icon {
      color: config.$primary-color;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: config.$primary-color;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-status-reject-icon {
      color: #ff0000;
      margin-left: 16%;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: #ff0000;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-tag {
      font-size: 14px;
      padding: 4px 8px;
      border: 1px solid #00000017;
    }
    .rating-each-toilet-table {
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }

    .rating-details-each-toilet-container {
      display: flex;
      margin-top: 2%;
      margin-left: 16px;
      font-weight: 500;
      .rating-details-each-toilet-content-container {
        margin-left: 2%;
        .rating-details-each-toilet-date {
          margin-top: 1%;
          margin-bottom: 1%;
          font-weight: 400;
        }
        .rating-details-each-toilet-content {
          margin-top: 2%;
          font-weight: 400;
          margin-bottom: 2%;
          // width: 50%;
        }
        .rating-details-each-toilet-img {
          width: 120px;
          height: 120px;
          margin-bottom: 2%;
          margin-right: 8px;
          margin-top: 4px;
          object-fit: cover;
        }
        .rating-details-each-toilet-status {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
        }
        .rating-details-each-toilet-status-deny {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
          background-color: #ff000046;
          color: #ff00008e;
          border: none;
        }
      }
    }
  }
}

@include Responsive(MD) {
  .rating-each-toilet-container {
    .rating-details-each-toilet-container {
      display: flex;
      margin-top: 2%;
      margin-left: 16px;
      font-weight: 500;
      .rating-details-each-toilet-content-container {
        margin-left: 2%;
        .rating-details-each-toilet-date {
          margin-top: 1%;
          margin-bottom: 1%;
          font-weight: 400;
        }
        .rating-details-each-toilet-content {
          margin-top: 2%;
          font-weight: 400;
          margin-bottom: 2%;
          width: 72%;
        }
        .rating-details-each-toilet-img {
          width: 120px;
          height: 120px;
          margin-bottom: 2%;
          margin-right: 8px;
          margin-top: 4px;
          object-fit: cover;
        }
        .rating-details-each-toilet-status {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
        }
        .rating-details-each-toilet-status-deny {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
          background-color: #ff000046;
          color: #ff00008e;
          border: none;
        }
      }
    }
  }
  .manage-toilet-container {
    border: 1px solid config.$secondary-color;
    margin-left: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 5px;
    padding-right: 10px;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .manage-toilet-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-popover-change-status {
    padding-top: 8px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .report-each-toilet-container {
    height: 98%;
    box-sizing: border-box;
    .report-each-toilet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid config.$secondary-color;
      padding: 1% 0 1% 1%;
      border-radius: 10px;
      .report-each-toilet-number-container {
        border-right: 1px solid config.$secondary-color;
        width: 50%;
        padding-right: 2%;
        &:nth-child(2) {
          padding-left: 32px;
          border: none;
        }
        .report-each-toilet-number {
          font-size: 14px;
        }
        .report-each-toilet-number-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .report-each-toilet-count-number {
            font-size: 24px;
            font-weight: 500;
          }
          .report-each-toilet-count-icon {
            font-size: 32px;
            box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
            border-radius: 12px;
            padding: 2%;
            color: config.$primary-color;
          }
        }
        .report-each-toilet-rate {
          opacity: 50%;
          font-size: 10px;
          margin-top: 2%;
          .report-each-toilet-icon {
            color: config.$primary-color;
          }
        }
      }
    }
  }
  .report-each-toilet-content-excel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .report-each-toilet-content-excel-btn {
      border: none;
      background-color: config.$primary-color;
      color: config.$w-color;
      padding: 8px 16px;
      border-radius: 16px;
      margin-left: 16px;
    }
  }
  .report-each-toilet-content {
    margin-top: 1.5%;
    border: 1px solid config.$secondary-color;
    padding: 0.25% 1%;
    border-radius: 10px;
    th {
      background-color: config.$w-color !important;
    }
    ul {
      justify-content: center;
      font-size: 12px !important;
      .ant-pagination-total-text {
        position: absolute;
        left: 1%;
        font-size: 12px !important;
      }
    }
    .ant-table-cell {
      background-color: config.$w-color !important;
      font-size: 12px !important;
    }
  }
  .account-each-toilet-container {
    .account-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 40%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 50%;
        }
      }
      .account-each-toilet-image-container {
        margin-left: 8%;
        .account-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .account-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      margin-bottom: 16px;
      p {
        width: 40%;
      }
      .account-each-toilet-input {
        width: 56%;
      }
    }
    .account-each-toilet-btn {
      margin-top: 8px;
    }
    .account-each-toilet-input-btn-container {
      margin-left: 26.5%;
      .account-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
  }

  .infor-each-toilet-container {
    .infor-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 24%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .infor-each-toilet-image-container {
        margin-left: 8%;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .infor-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-bottom: 16px;
      p {
        width: 30%;
      }
      .infor-each-toilet-input {
        width: 60%;
      }
      .infor-each-toilet-image-container {
        width: 60%;
        display: flex;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
        .infor-each-toilet-add-image {
          display: inline-flex;
          background-color: config.$secondary-color;
          width: 80px;
          height: 80px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 1px dashed config.$black-color;
        }
      }
    }
    .infor-each-toilet-input-btn-container {
      margin-left: 32%;
      .infor-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
    .infor-each-toilet-btn {
      margin-top: 16px;
    }
  }

  .config-each-toilet-container {
    .config-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .config-each-toilet-image-container {
        margin-left: 8%;
        .config-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .config-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
        }
      }
      .config-each-toilet-input {
        width: 24%;
      }
      .config-each-toilet-input-tag {
        width: 400px;
      }
      .config-each-toilet-input-number {
        width: 80px;
      }
    }
    .config-each-toilet-btn {
      margin-top: 16px;
    }
    .config-each-toilet-input-btn-container {
      margin-left: 40%;
      margin-top: 4%;
      .config-each-toilet-save-btn {
        margin-right: 8px;
      }
    }

    .config-each-toilet-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-toilet-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .create-new-toilet-footer-container {
      margin-top: 40px;
    }
    .create-new-toilet-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
  }
  .create-new-toilet-input-box-container {
    margin-top: 40px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    .create-new-toilet-input-left-container {
      width: 50%;
      input[type="file"] {
        padding: 0;
        border: none;
      }
      input[type="file"]::file-selector-button {
        border: none;
        background: config.$primary-color;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }

      input[type="file"]::file-selector-button:hover {
        background: #0d45a5;
      }
    }
    .create-new-toilet-input-right-container {
      width: 44%;
    }
    .create-new-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 40%;
      }
      .create-new-toilet-input {
        width: 60%;
      }
      .create-new-toilet-image-container {
        width: 60%;
        white-space: nowrap;
        .create-new-toilet-image-appear-container {
          position: relative;
          .create-new-toilet-image-icon {
            position: absolute;
            right: 6%;
            top: 0%;
            color: #ff0000c8;
            padding: 4%;
            opacity: 100%;
            padding: 2%;
            background-color: config.$w-color;
            border-radius: 50%;
            font-size: 16px;
            &:hover {
              color: #ff0000;
            }
          }
        }
        .create-new-toilet-image {
          width: 56px;
          height: 56px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-toilet-btn {
      margin-top: 16px;
      margin-left: 15%;
    }
    .create-new-toilet-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  .create-new-config-input-box-container {
    margin-top: 40px;
    .create-new-config-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
          margin-right: 4%;
          line-height: 1.5em;
        }
      }
      .create-new-config-input {
        width: 50%;
      }
      .create-new-config-input-tag {
        width: 320px;
      }
      .create-new-config-input-number {
        width: 160px;
      }
    }
    .create-new-config-btn {
      margin-top: 16px;
      margin-left: 25%;
    }
    .create-new-config-save-btn {
      margin-left: 23%;
      margin-top: 8px;
    }
    .create-new-config-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-account-input-box-container {
    margin-top: 40px;
    .create-new-account-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-bottom: 16px;
      p {
        width: 30%;
      }
      .create-new-account-input {
        width: 60%;
      }
    }
    .create-new-account-btn {
      margin-top: 16px;
    }
    .create-new-account-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }

  //
  .create-new-approve-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    font-size: 14px;
    .create-new-approve-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 120px;
        }
        &:nth-child(2) {
          margin-left: 16px;
          font-weight: 500;
          width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
      .create-new-approve-toilet-image-container {
        width: 200px;
        white-space: nowrap;
        margin-left: 16px;
        margin-right: 16px;
        .create-new-approve-toilet-image {
          width: 50px;
          height: 50px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      p {
        &:first-child {
          opacity: 50%;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  //report
  //rating
  .rating-each-toilet-filter-container {
    .rating-each-toilet-filter-header-text {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-header-text-star {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      margin-top: 4%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-checkbox {
      margin-bottom: 1%;
    }
    .rating-each-toilet-filter-btn-container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 8%;
    }
  }

  .rating-each-toilet-container {
    .rating-each-toilet-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: config.$font-subHeader;
        font-weight: 600;
        color: config.$primary-color;
      }
    }
    .rating-each-toilet-status-solve-icon {
      color: config.$primary-color;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: config.$primary-color;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-status-reject-icon {
      color: #ff0000;
      margin-left: 16%;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: #ff0000;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-tag {
      font-size: 14px;
      padding: 4px 8px;
      border: 1px solid #00000017;
    }
    .rating-each-toilet-table {
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}

@include Responsive(SM) {
  .rating-each-toilet-container {
    .rating-details-each-toilet-container {
      display: flex;
      margin-top: 2%;
      margin-left: 16px;
      font-weight: 500;
      .rating-details-each-toilet-content-container {
        margin-left: 2%;
        .rating-details-each-toilet-date {
          margin-top: 1%;
          margin-bottom: 1%;
          font-weight: 400;
        }
        .rating-details-each-toilet-content {
          margin-top: 2%;
          font-weight: 400;
          margin-bottom: 2%;
          width: 100%;
        }
        .rating-details-each-toilet-img {
          width: 80px;
          height: 80px;
          margin-bottom: 2%;
          margin-right: 8px;
          margin-top: 4px;
          object-fit: cover;
        }
        .rating-details-each-toilet-status {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
        }
        .rating-details-each-toilet-status-deny {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
          background-color: #ff000046;
          color: #ff00008e;
          border: none;
        }
      }
    }
  }
  .manage-toilet-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 24px;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .manage-toilet-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-popover-change-status {
    padding-top: 8px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .report-each-toilet-container {
    height: 98%;
    box-sizing: border-box;
    .report-each-toilet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid config.$secondary-color;
      padding: 1% 0 1% 1%;
      border-radius: 10px;
      .report-each-toilet-number-container {
        border-right: 1px solid config.$secondary-color;
        width: 50%;
        padding-right: 2%;
        &:nth-child(2) {
          padding-left: 32px;
          border: none;
        }
        .report-each-toilet-number {
          font-size: 14px;
        }
        .report-each-toilet-number-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .report-each-toilet-count-number {
            font-size: 24px;
            font-weight: 500;
          }
          .report-each-toilet-count-icon {
            font-size: 32px;
            box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
            border-radius: 12px;
            padding: 2%;
            color: config.$primary-color;
          }
        }
        .report-each-toilet-rate {
          opacity: 50%;
          font-size: 10px;
          margin-top: 2%;
          .report-each-toilet-icon {
            color: config.$primary-color;
          }
        }
      }
    }
  }
  .report-each-toilet-content-excel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .report-each-toilet-content-excel-btn {
      border: none;
      background-color: config.$primary-color;
      color: config.$w-color;
      padding: 8px 16px;
      border-radius: 16px;
      margin-left: 16px;
    }
  }
  .report-each-toilet-content {
    margin-top: 1.5%;
    border: 1px solid config.$secondary-color;
    padding: 0.25% 1%;
    border-radius: 10px;
    th {
      background-color: config.$w-color !important;
    }
    ul {
      justify-content: center;
      font-size: 12px !important;
      .ant-pagination-total-text {
        position: absolute;
        left: 1%;
        font-size: 12px !important;
      }
    }
    .ant-table-cell {
      background-color: config.$w-color !important;
      font-size: 12px !important;
    }
  }
  .account-each-toilet-container {
    .account-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 60%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 50%;
        }
      }
      .account-each-toilet-image-container {
        margin-left: 8%;
        .account-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .account-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-bottom: 16px;
      p {
        width: 40%;
      }
      .account-each-toilet-input {
        width: 56%;
      }
    }
    .account-each-toilet-btn {
      margin-top: 8px;
    }
    .account-each-toilet-input-btn-container {
      margin-left: 35.5%;
      .account-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
  }

  .infor-each-toilet-container {
    .infor-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 120px;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
        }
      }
      .infor-each-toilet-image-container {
        margin-left: 8%;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .infor-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-bottom: 16px;
      p {
        width: 30%;
      }
      .infor-each-toilet-input {
        width: 60%;
      }
      .infor-each-toilet-image-container {
        width: 60%;
        display: flex;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
        .infor-each-toilet-add-image {
          display: inline-flex;
          background-color: config.$secondary-color;
          width: 80px;
          height: 80px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 1px dashed config.$black-color;
        }
      }
    }
    .infor-each-toilet-input-btn-container {
      margin-left: 32%;
      .infor-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
    .infor-each-toilet-btn {
      margin-top: 16px;
    }
  }

  .config-each-toilet-container {
    .config-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:nth-child(2) {
          margin-left: 8%;
          width: 300px;
          font-weight: 500;
        }
      }
      .config-each-toilet-image-container {
        margin-left: 8%;
        .config-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .config-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .config-each-toilet-input {
        width: 40%;
      }
      .config-each-toilet-input-tag {
        width: 60%;
      }
      .config-each-toilet-input-number {
        width: 160px;
      }
    }
    .config-each-toilet-btn {
      margin-top: 16px;
    }
    .config-each-toilet-input-btn-container {
      margin-left: 40%;
      margin-top: 4%;
      .config-each-toilet-save-btn {
        margin-right: 8px;
      }
    }

    .config-each-toilet-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-toilet-box-container {
    box-sizing: border-box;
    margin-left: 40px;
    height: 98%;
    border: 1px solid config.$secondary-color;
    height: 98%;
    border-radius: 10px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    padding: 24px;
    .create-new-toilet-footer-container {
      margin-top: 16px;
    }
    .create-new-toilet-box-title {
      font-size: 26px;
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-step-container {
      width: 80%;
      margin: auto;
      margin-top: 40px;
    }
  }
  .create-new-toilet-input-box-container {
    // margin-top: 40px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column-reverse;
    .create-new-toilet-input-left-container {
      width: 100%;
      input[type="file"] {
        padding: 0;
        border: none;
      }
      input[type="file"]::file-selector-button {
        border: none;
        background: config.$primary-color;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }

      input[type="file"]::file-selector-button:hover {
        background: #0d45a5;
      }
    }
    .create-new-toilet-input-right-container {
      width: 100%;
      height: 25vh;
      margin: auto;
      margin-bottom: 4%;
    }
    .create-new-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .create-new-toilet-input {
        width: 60%;
      }
      .create-new-toilet-image-container {
        width: 60%;
        white-space: nowrap;
        .create-new-toilet-image-appear-container {
          position: relative;
          .create-new-toilet-image-icon {
            position: absolute;
            right: 6%;
            top: 0%;
            color: #ff0000c8;
            padding: 4%;
            opacity: 100%;
            padding: 2%;
            background-color: config.$w-color;
            border-radius: 50%;
            font-size: 16px;
            &:hover {
              color: #ff0000;
            }
          }
        }
        .create-new-toilet-image {
          width: 56px;
          height: 56px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-toilet-btn {
      margin-top: 16px;
      margin-left: 15%;
    }
    .create-new-toilet-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  .create-new-config-input-box-container {
    // margin-top: 40px;
    .create-new-config-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
          margin-right: 4%;
          line-height: 1.5em;
        }
      }
      .create-new-config-input {
        width: 60%;
      }
      .create-new-config-input-tag {
        width: 60%;
      }
      .create-new-config-input-number {
        width: 160px;
      }
    }
    .create-new-config-btn {
      margin-top: 16px;
      margin-left: 25%;
    }
    .create-new-config-save-btn {
      margin-left: 23%;
      margin-top: 8px;
    }
    .create-new-config-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-account-input-box-container {
    // margin-top: 40px;
    .create-new-account-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 36%;
      }
      .create-new-account-input {
        width: 60%;
      }
    }
    .create-new-account-btn {
      margin-top: 16px;
    }
    .create-new-account-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  //
  .create-new-approve-container {
    // margin-top: 24px;
    font-size: 14px;
    .create-new-approve-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 200px;
        }
        &:nth-child(2) {
          margin-left: 24px;
          width: 320px;
          font-weight: 500;
        }
      }
      .create-new-approve-toilet-image-container {
        width: 256px;
        white-space: nowrap;
        .create-new-approve-toilet-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;

      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 24px;
          width: 320px;
          font-weight: 500;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  //rating
  .rating-each-toilet-filter-container {
    .rating-each-toilet-filter-header-text {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-header-text-star {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      margin-top: 4%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-checkbox {
      margin-bottom: 1%;
    }
    .rating-each-toilet-filter-btn-container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 8%;
    }
  }

  .rating-each-toilet-container {
    .rating-each-toilet-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: config.$font-subHeader;
        font-weight: 600;
        color: config.$primary-color;
      }
    }
    .rating-each-toilet-status-solve-icon {
      color: config.$primary-color;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: config.$primary-color;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-status-reject-icon {
      color: #ff0000;
      margin-left: 16%;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: #ff0000;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-tag {
      font-size: 14px;
      padding: 4px 8px;
      border: 1px solid #00000017;
    }
    .rating-each-toilet-table {
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}

@include Responsive(XS) {
  .rating-each-toilet-container {
    .rating-details-each-toilet-container {
      display: flex;
      margin-top: 2%;
      margin-left: 16px;
      font-weight: 500;
      .rating-details-each-toilet-content-container {
        margin-left: 2%;
        .rating-details-each-toilet-date {
          margin-top: 1%;
          margin-bottom: 1%;
          font-weight: 400;
        }
        .rating-details-each-toilet-content {
          margin-top: 2%;
          font-weight: 400;
          margin-bottom: 2%;
          width: 100%;
        }
        .rating-details-each-toilet-img {
          width: 80px;
          height: 80px;
          margin-bottom: 2%;
          margin-right: 8px;
          margin-top: 4px;
          object-fit: cover;
        }
        .rating-details-each-toilet-status {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
        }
        .rating-details-each-toilet-status-deny {
          display: flex;
          font-size: config.$font-content;
          font-style: italic;
          align-items: center;
          background-color: #ff000046;
          color: #ff00008e;
          border: none;
        }
      }
    }
  }
  .manage-toilet-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    box-sizing: border-box;
    height: 98%;
    padding: 24px;
    .ant-table {
      font-size: 12px !important;
    }
    .manage-toilet-table-content {
      margin-top: 16px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        font-size: 12px !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-badge-status-text {
        font-size: 12px;
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
      .manage-toilet-unit-btn-more {
        border: none;
        outline: none;
        box-shadow: none;
        height: 22px;
      }
    }
  }
  .manage-toilet-popover-see-infor {
    padding-bottom: 8px;
    border-bottom: 1px solid config.$secondary-color;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .manage-toilet-popover-change-status {
    padding-top: 8px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: config.$primary-color;
    }
  }
  .report-each-toilet-container {
    height: 98%;
    box-sizing: border-box;
    .report-each-toilet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid config.$secondary-color;
      padding: 2% 0 2% 2%;
      border-radius: 10px;
      .report-each-toilet-number-container {
        border-right: 1px solid config.$secondary-color;
        width: 50%;
        padding-right: 2%;
        &:nth-child(2) {
          padding-left: 16px;
          border: none;
        }
        .report-each-toilet-number {
          font-size: 12px;
        }
        .report-each-toilet-number-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .report-each-toilet-count-number {
            font-size: 14px;
            font-weight: 500;
          }
          .report-each-toilet-count-icon {
            font-size: 16px;
            box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
            border-radius: 12px;
            padding: 2%;
            color: config.$primary-color;
          }
        }
        .report-each-toilet-rate {
          opacity: 50%;
          font-size: 10px;
          margin-top: 2%;
          .report-each-toilet-icon {
            color: config.$primary-color;
          }
        }
      }
    }
  }
  .report-each-toilet-content-excel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .report-each-toilet-content-excel-btn {
      border: none;
      background-color: config.$primary-color;
      color: config.$w-color;
      padding: 6px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 16px;
      font-size: 10px;
      margin-left: 16px;
    }
  }
  .report-table-header-date {
    input {
      font-size: 10px !important;
    }
  }
  .report-each-toilet-content {
    margin-top: 4%;
    border: 1px solid config.$secondary-color;
    padding: 0.25% 1%;
    border-radius: 10px;
    th {
      background-color: config.$w-color !important;
    }
    ul {
      justify-content: center;
      font-size: 12px !important;
      .ant-pagination-total-text {
        position: absolute;
        left: 1%;
        font-size: 12px !important;
      }
    }
    .ant-table-cell {
      background-color: config.$w-color !important;
      font-size: 12px !important;
    }
  }
  .account-each-toilet-container {
    .account-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 50%;
        }
      }
      .account-each-toilet-image-container {
        margin-left: 8%;
        .account-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .account-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .account-each-toilet-input {
        width: 56%;
      }
    }
    .account-each-toilet-btn {
      margin-top: 8px;
    }
    .account-each-toilet-input-btn-container {
      margin-left: 44.5%;
      .account-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
  }

  .infor-each-toilet-container {
    .infor-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 30%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:nth-child(2) {
          margin-left: 8%;
          width: 60%;
          font-weight: 500;
        }
      }
      .infor-each-toilet-image-container {
        margin-left: 8%;
        .infor-each-toilet-image {
          width: 40px;
          height: 40px;
          margin-right: 4px;
          border-radius: 4px;
        }
      }
    }
    .infor-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 40%;
      }
      .infor-each-toilet-input {
        width: 60%;
      }
      .infor-each-toilet-image-container {
        width: 60%;
        display: flex;
        .infor-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
        .infor-each-toilet-add-image {
          display: inline-flex;
          background-color: config.$secondary-color;
          width: 80px;
          height: 80px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 1px dashed config.$black-color;
        }
      }
    }
    .infor-each-toilet-input-btn-container {
      margin-left: 40%;
      .infor-each-toilet-save-btn {
        margin-right: 8px;
      }
    }
    .infor-each-toilet-btn {
      margin-top: 16px;
    }
  }

  .config-each-toilet-container {
    .config-each-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
          width: 200px;
        }
      }
      .config-each-toilet-image-container {
        margin-left: 8%;
        .config-each-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
    .config-each-toilet-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      font-size: 12px;
      p {
        &:first-child {
          opacity: 80%;
          width: 40%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      input {
        font-size: 12px !important;
      }
      span {
        font-size: 12px !important;
      }
      .config-each-toilet-input {
        width: 60%;
      }
      .config-each-toilet-input-tag {
        width: 60%;
      }
      .config-each-toilet-input-number {
        width: 160px;
      }
    }
    .config-each-toilet-btn {
      margin-top: 16px;
    }
    .config-each-toilet-input-btn-container {
      margin-left: 40%;
      margin-top: 4%;
      .config-each-toilet-save-btn {
        margin-right: 8px;
      }
    }

    .config-each-toilet-checkbox {
      width: 50%;
      margin: -12px 0 0 0;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-toilet-box-container {
    box-sizing: border-box;
    height: 98%;
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
    background-color: config.$w-color;
    .create-new-toilet-footer-container {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .create-new-toilet-box-title {
      font-weight: 500;
      color: config.$primary-color;
    }
    .create-new-toilet-step-container {
      width: 80%;
      margin: auto;
      margin-top: 24px;
    }
  }
  .create-new-toilet-input-box-container {
    // margin-top: 16px;
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    .create-new-toilet-input-left-container {
      width: 100%;
      input[type="file"] {
        padding: 0;
        border: none;
      }
      input[type="file"]::file-selector-button {
        border: none;
        background: config.$primary-color;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }

      input[type="file"]::file-selector-button:hover {
        background: #0d45a5;
      }
    }
    .create-new-toilet-input-right-container {
      width: 100%;
      height: 25vh;
      margin-bottom: 4%;
    }
    .create-new-toilet-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;
      font-size: 12px;
      p {
        width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .create-new-toilet-input {
        width: 60%;
        font-size: 12px;
      }
      .create-new-toilet-image-container {
        width: 60%;
        white-space: nowrap;
        .create-new-toilet-image-appear-container {
          position: relative;
          .create-new-toilet-image-icon {
            position: absolute;
            right: 6%;
            top: 0%;
            color: #ff0000c8;
            padding: 4%;
            opacity: 100%;
            padding: 2%;
            background-color: config.$w-color;
            border-radius: 50%;
            font-size: 12px;
            &:hover {
              color: #ff0000;
            }
          }
        }
        .create-new-toilet-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-toilet-btn {
      margin-top: 16px;
      margin-left: 15%;
    }
    .create-new-toilet-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  .create-new-config-input-box-container {
    // margin-top: 8px;
    .create-new-config-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;
      font-size: 12px !important;
      input {
        font-size: 12px !important;
      }
      span {
        font-size: 12px !important;
      }
      p {
        &:first-child {
          width: 36%;
          font-size: 12px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          margin-right: 4%;
        }
      }
      .create-new-config-input {
        width: 60%;
        font-size: 12px !important;
      }
      .ant-picker {
        font-size: 12px !important;
      }
      .create-new-config-input-tag {
        width: 60%;
        font-size: 12px !important;
      }
      .ant-select-selector {
        font-size: 12px !important;
      }
      .ant-select-selection-item {
        font-size: 12px !important;
      }
      .create-new-config-input-number {
        width: 80px;
        font-size: 12px !important;
      }
    }
    .create-new-config-btn {
      margin-top: 16px;
      margin-left: 25%;
    }
    .create-new-config-save-btn {
      margin-left: 23%;
      margin-top: 8px;
    }
    .create-new-config-checkbox {
      width: 50%;
    }
    .ant-select-selection-item {
      background-color: config.$primary-color;
      color: config.$w-color;
      .ant-select-selection-item-remove {
        color: config.$w-color;
      }
    }
  }

  .create-new-account-input-box-container {
    // margin-top: 16px;
    .create-new-account-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      p {
        width: 30%;
        font-size: 12px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .create-new-account-input {
        width: 60%;
        font-size: 12px !important;
      }
    }
    .create-new-account-btn {
      margin-top: 16px;
    }
    .create-new-account-save-btn {
      margin-left: 17%;
      margin-top: 8px;
    }
  }
  //
  .create-new-approve-container {
    font-size: 14px;
    .create-new-approve-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 160px;
        }
        &:nth-child(2) {
          margin-left: 16px;
          width: 300px;
          font-weight: 500;
        }
      }
      .create-new-approve-toilet-image-container {
        width: 240px;
        white-space: nowrap;
        .create-new-approve-toilet-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          padding: 0.2em;
          border: 1px dashed #0000002b;
          border-radius: 4px;
          margin-right: 2%;
        }
      }
    }
    .create-new-approve-config-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      align-items: center;

      // color: config.$primary-color;
      p {
        &:first-child {
          opacity: 50%;
          width: 160px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
        &:nth-child(2) {
          margin-left: 16px;
          width: 300px;
          font-weight: 500;
        }
      }
      .create-new-approve-config-toilet-image-container {
        margin-left: 8%;
        .create-new-approve-config-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  //report
  //rating
  .rating-each-toilet-filter-container {
    .rating-each-toilet-filter-header-text {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-header-text-star {
      border-bottom: 1px solid config.$secondary-color;
      margin-bottom: 2%;
      padding-bottom: 2%;
      margin-top: 4%;
      color: config.$primary-color;
      font-weight: 600;
      font-size: config.$font-subHeader;
    }
    .rating-each-toilet-filter-checkbox {
      margin-bottom: 1%;
    }
    .rating-each-toilet-filter-btn-container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 8%;
    }
  }

  .rating-each-toilet-container {
    .rating-each-toilet-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: config.$font-subHeader;
        font-weight: 600;
        color: config.$primary-color;
      }
    }
    .rating-each-toilet-status-solve-icon {
      color: config.$primary-color;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: config.$primary-color;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-status-reject-icon {
      color: #ff0000;
      margin-left: 16%;
      font-size: config.$font-subHeader;
      &:hover {
        background-color: #ff0000;
        color: config.$w-color;
        border-radius: 50%;
      }
    }
    .rating-each-toilet-tag {
      font-size: 14px;
      padding: 4px 8px;
      border: 1px solid #00000017;
    }
    .rating-each-toilet-table {
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}
