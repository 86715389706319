$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.print-card-container {
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
  background-color: config.$w-color;
}

@include Responsive(XL) {
  .print-card-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 40px;
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    .print-card {
      margin-top: 24px;
      .print-card-phone {
        margin-bottom: 8px;
      }
    }

    .print-card-qr-container-box {
      margin-top: 2%;
      .print-card-qr-container {
        margin-right: 2%;
        margin-bottom: 1%;
        margin-top: 8px;
        height: 204.1px;
        width: 340.16px;
        border: 0.5px solid config.$secondary-color;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .print-card-qr-right {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 4px 0 32px;
        }
        .print-card-qr-left {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 0 32px 0 4px;
        }
        .print-card-qr-code {
          margin-top: 24px;
        }
        .print-card-qr-img-container {
          position: absolute;
          display: flex;
          align-items: center;
          top: 8px;
          left: 8px;
          font-size: 12px;
          .print-card-qr-img {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include Responsive(MD) {
  .print-card-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 40px;
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    .print-card {
      margin-top: 24px;
      .print-card-phone {
        margin-bottom: 8px;
      }
    }

    .print-card-qr-container-box {
      margin-top: 2%;
      .print-card-qr-container {
        margin-right: 2%;
        margin-bottom: 1%;
        margin-top: 8px;
        height: 204.1px;
        width: 340.16px;
        border: 0.5px solid config.$secondary-color;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .print-card-qr-right {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 4px 0 32px;
        }
        .print-card-qr-left {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 0 32px 0 4px;
        }
        .print-card-qr-code {
          margin-top: 24px;
        }
        .print-card-qr-img-container {
          position: absolute;
          display: flex;
          align-items: center;
          top: 8px;
          left: 8px;
          font-size: 12px;
          .print-card-qr-img {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include Responsive(SM) {
  .print-card-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 40px;
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    .print-card {
      margin-top: 24px;
      .print-card-phone {
        margin-bottom: 8px;
      }
    }

    .print-card-qr-container-box {
      margin-top: 2%;
      .print-card-qr-container {
        margin-right: 2%;
        margin-bottom: 1%;
        margin-top: 8px;
        height: 204.1px;
        width: 340.16px;
        border: 0.5px solid config.$secondary-color;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .print-card-qr-right {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 4px 0 32px;
        }
        .print-card-qr-left {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 0 32px 0 4px;
        }
        .print-card-qr-code {
          margin-top: 24px;
        }
        .print-card-qr-img-container {
          position: absolute;
          display: flex;
          align-items: center;
          top: 8px;
          left: 8px;
          font-size: 12px;
          .print-card-qr-img {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include Responsive(XS) {
  .print-card-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
    height: 98%;
    .print-card {
      margin-top: 24px;
      .print-card-phone {
        margin-bottom: 8px;
      }
      .print-card-input {
        width: 320px !important;
      }
    }

    .print-card-qr-container-box {
      margin-top: 4%;
      .print-card-qr-container {
        margin-right: 2%;
        margin-bottom: 1%;
        margin-top: 8px;
        height: 204.1px;
        width: 340.16px;
        border: 0.5px solid config.$secondary-color;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .print-card-qr-right {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 4px 0 32px;
        }
        .print-card-qr-left {
          background-color: config.$primary-color;
          width: 120px;
          height: 24px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 0 32px 0 4px;
        }
        .print-card-qr-code {
          margin-top: 24px;
        }
        .print-card-qr-img-container {
          position: absolute;
          display: flex;
          align-items: center;
          top: 8px;
          left: 8px;
          font-size: 12px;
          .print-card-qr-img {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
