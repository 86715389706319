$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../..//App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}


@include Responsive(XL) {
  .account-toilet-container {
    .account-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 20%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .account-toilet-image-container {
        margin-left: 8%;
        .account-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 4%;
          border-radius: 4px;
        }
      }
    }

    .account-toilet-input-container-box {
      width: 50%;
      box-sizing: border-box;
      .account-toilet-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        p {
          width: 40%;
        }
        .account-toilet-input {
          width: 56%;
        }
      }
      .account-toilet-btn {
        margin-top: 16px;
      }
      .account-toilet-save-btn {
        margin-left: 44%;
        margin-top: 8px;
      }
    }
  }
  .config-infor-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1%;
    .config-infor-ava-container {
      width: 20%;
    }
    .config-infor-ava {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin-left: 24px;
    }

    .config-infor-toilet-container {
      width: 70%;
      .config-infor-toilet {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 48px;
        font-size: config.$font-subHeader;
        p {
          &:first-child {
            opacity: 80%;
            width: 16%;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 72%;
          }
        }
      }
    }
  }
}

@include Responsive(MD) {
  .account-toilet-container {
    .account-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 20%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .account-toilet-image-container {
        margin-left: 8%;
        .account-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 4%;
          border-radius: 4px;
        }
      }
    }

    .account-toilet-input-container-box {
      width: 50%;
      box-sizing: border-box;
      .account-toilet-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        p {
          width: 40%;
        }
        .account-toilet-input {
          width: 56%;
        }
      }
      .account-toilet-btn {
        margin-top: 16px;
      }
      .account-toilet-save-btn {
        margin-left: 44%;
        margin-top: 8px;
      }
    }
  }
  .config-infor-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1%;
    .config-infor-ava-container {
      width: 20%;
    }
    .config-infor-ava {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-left: 24px;
    }

    .config-infor-toilet-container {
      width: 70%;
      .config-infor-toilet {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 48px;
        font-size: config.$font-subHeader;
        p {
          &:first-child {
            opacity: 80%;
            width: 24%;
          }
          &:nth-child(2) {
            margin-left: 6%;
            font-weight: 500;
            width: 70%;
          }
        }
      }
    }
  }
}

@include Responsive(SM) {
  .account-toilet-container {
    .account-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 50%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .account-toilet-image-container {
        margin-left: 8%;
        .account-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 4%;
          border-radius: 4px;
        }
      }
    }

    .account-toilet-input-container-box {
      width: 100%;
      box-sizing: border-box;
      .account-toilet-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        p {
          width: 40%;
        }
        .account-toilet-input {
          width: 56%;
        }
      }
      .account-toilet-btn {
        margin-top: 16px;
      }
      .account-toilet-save-btn {
        margin-left: 44%;
        margin-top: 8px;
      }
    }
  }
  .config-infor-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1%;
    .config-infor-ava-container {
      width: 20%;
    }
    .config-infor-ava {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-left: 24px;
    }

    .config-infor-toilet-container {
      width: 70%;
      margin-left: 8%;
      .config-infor-toilet {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 48px;
        font-size: config.$font-subHeader;
        p {
          &:first-child {
            opacity: 80%;
            width: 40%;
          }
          &:nth-child(2) {
            margin-left: 4%;
            font-weight: 500;
            width: 56%;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@include Responsive(XS) {
  .account-toilet-container {
    .account-toilet {
      display: flex;
      flex-direction: row;
      text-align: left;
      line-height: 40px;
      width: 100%;
      p {
        &:first-child {
          opacity: 80%;
          width: 60%;
        }
        &:nth-child(2) {
          margin-left: 8%;
          font-weight: 500;
        }
      }
      .account-toilet-image-container {
        margin-left: 8%;
        .account-toilet-image {
          width: 80px;
          height: 80px;
          margin-right: 4%;
          border-radius: 4px;
        }
      }
    }

    .account-toilet-input-container-box {
      width: 100%;
      box-sizing: border-box;
      .account-toilet-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        p {
          width: 40%;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }
        .account-toilet-input {
          width: 56%;
        }
      }
      .account-toilet-btn {
        margin-top: 16px;
      }
      .account-toilet-save-btn {
        margin-left: 44%;
        margin-top: 8px;
      }
    }
  }
  .config-infor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1%;
    .config-infor-ava {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      margin: 8% 0;
    }

    .config-infor-toilet-container {
      width: 100%;
      .config-infor-toilet {
        display: flex;
        flex-direction: row;
        text-align: left;
        line-height: 40px;
        font-size: 14px;
        p {
          &:first-child {
            opacity: 80%;
            width: 30%;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
          }
          &:nth-child(2) {
            margin-left: 8%;
            font-weight: 500;
            width: 62%;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
