$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@include Responsive(XL) {
  .login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: config.$secondary-color;
    .login-left-container {
      display: flex;
      flex-direction: column;
      background-color: config.$w-color;
      align-items: center;
      justify-content: center;
      width: 32%;
      height: 88%;
      border-radius: 24px 0 0 24px;
      .login-left-logo {
        width: 200px;
        height: 200px;
      }
      .login-left-welcome {
        font-size: 24px;
        margin-top: 8%;
        color: config.$primary-color;
        font-weight: 500;
      }
      .login-left-title {
        font-size: 28px;
        margin-top: 2%;
        color: config.$primary-color;
        font-weight: 500;
      }
    }
    .login-right-container {
      display: flex;
      flex-direction: column;
      background-color: config.$primary-color;
      align-items: center;
      justify-content: center;
      width: 32%;
      height: 88%;
      border-radius: 0 24px 24px 0;
      .login-right-title {
        font-size: 36px;
        color: config.$w-color;
      }
      .login-right-username-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-username {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-username-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-password-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-password {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-password-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-remember-password-container {
        margin-top: 8%;
        display: flex;
        align-items: center;
        width: 80%;
        .login-right-remember-password-input {
          width: 16px;
          height: 16px;
          border-radius: 2px !important;
          border: none !important;
          outline: none !important;
          margin-right: 2%;
        }
        .login-right-remember-password {
          font-size: config.$font-content;
          color: config.$w-color;
        }
      }
      .login-right-btn {
        background-color: config.$w-color;
        width: 80%;
        margin-left: 2%;
        height: 6%;
        margin-top: 8%;
        border: none;
        border-radius: 16px;
        font-size: config.$font-subHeader;
        color: config.$primary-color;
        font-weight: 600;
        letter-spacing: 0.04em;
        opacity: 80%;
        cursor: pointer;
        &:hover {
          opacity: 100%;
        }
      }
    }
  }
}
@include Responsive(MD) {
  .login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: config.$secondary-color;
    .login-left-container {
      display: flex;
      flex-direction: column;
      background-color: config.$w-color;
      align-items: center;
      justify-content: center;
      width: 42%;
      height: 88%;
      border-radius: 24px 0 0 24px;
      .login-left-logo {
        width: 200px;
        height: 200px;
      }
      .login-left-welcome {
        font-size: 20px;
        margin-top: 8%;
        color: config.$primary-color;
        font-weight: 500;
      }
      .login-left-title {
        font-size: 26px;
        margin-top: 2%;
        color: config.$primary-color;
        font-weight: 500;
      }
    }
    .login-right-container {
      display: flex;
      flex-direction: column;
      background-color: config.$primary-color;
      align-items: center;
      justify-content: center;
      width: 42%;
      height: 88%;
      border-radius: 0 24px 24px 0;
      .login-right-title {
        font-size: 36px;
        color: config.$w-color;
      }
      .login-right-username-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-username {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-username-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-password-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-password {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-password-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-remember-password-container {
        margin-top: 8%;
        display: flex;
        align-items: center;
        width: 80%;
        .login-right-remember-password-input {
          width: 16px;
          height: 16px;
          border-radius: 2px !important;
          border: none !important;
          outline: none !important;
          margin-right: 2%;
        }
        .login-right-remember-password {
          font-size: config.$font-content;
          color: config.$w-color;
        }
      }
      .login-right-btn {
        background-color: config.$w-color;
        width: 80%;
        margin-left: 2%;
        height: 6%;
        margin-top: 8%;
        border: none;
        border-radius: 16px;
        font-size: config.$font-subHeader;
        color: config.$primary-color;
        font-weight: 600;
        letter-spacing: 0.04em;
        opacity: 80%;
        cursor: pointer;
        &:hover {
          opacity: 100%;
        }
      }
    }
  }
}
@include Responsive(SM) {
  .login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: config.$secondary-color;
    .login-left-container {
      display: flex;
      flex-direction: column;
      background-color: config.$w-color;
      align-items: center;
      justify-content: center;
      width: 44%;
      height: 72%;
      border-radius: 24px 0 0 24px;
      .login-left-logo {
        width: 200px;
        height: 200px;
      }
      .login-left-welcome {
        font-size: 16px;
        margin-top: 8%;
        color: config.$primary-color;
        font-weight: 500;
      }
      .login-left-title {
        font-size: 20px;
        margin-top: 2%;
        color: config.$primary-color;
        font-weight: 500;
      }
    }
    .login-right-container {
      display: flex;
      flex-direction: column;
      background-color: config.$primary-color;
      align-items: center;
      justify-content: center;
      width: 44%;
      height: 72%;
      border-radius: 0 24px 24px 0;
      .login-right-title {
        font-size: 26px;
        color: config.$w-color;
      }
      .login-right-username-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-username {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-username-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-password-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-password {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-password-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-remember-password-container {
        margin-top: 8%;
        display: flex;
        align-items: center;
        width: 80%;
        .login-right-remember-password-input {
          width: 16px;
          height: 16px;
          border-radius: 2px !important;
          border: none !important;
          outline: none !important;
          margin-right: 2%;
        }
        .login-right-remember-password {
          font-size: config.$font-content;
          color: config.$w-color;
        }
      }
      .login-right-btn {
        background-color: config.$w-color;
        width: 80%;
        margin-left: 2%;
        height: 6%;
        margin-top: 8%;
        border: none;
        border-radius: 16px;
        font-size: config.$font-subHeader;
        color: config.$primary-color;
        font-weight: 600;
        letter-spacing: 0.04em;
        opacity: 80%;
        cursor: pointer;
        &:hover {
          opacity: 100%;
        }
      }
    }
  }
}
@include Responsive(XS) {
  .login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: config.$secondary-color;
    .login-left-container {
      display: none;
    }
    .login-right-container {
      display: flex;
      flex-direction: column;
      background-color: config.$primary-color;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 88%;
      border-radius: 24px 24px;
      .login-right-title {
        font-size: 36px;
        color: config.$w-color;
      }
      .login-right-username-container {
        margin-top: 16%;
        width: 100%;
        margin-left: 20%;
        .login-right-username {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-username-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-password-container {
        margin-top: 8%;
        width: 100%;
        margin-left: 20%;
        .login-right-password {
          color: config.$w-color;
          letter-spacing: 0.04em;
          margin-bottom: 8px;
        }
        .login-right-password-input {
          width: 80%;
          height: 80%;
          border-radius: 8px;
          border: 1px solid config.$w-color;
          background: none;
          text-indent: 8px;
          color: config.$w-color;
          opacity: 80%;
          &::placeholder {
            color: config.$w-color;
            opacity: 50%;
          }
          &:focus {
            border: 1px solid config.$w-color;
            outline: none;
            opacity: 100%;
          }
        }
      }
      .login-right-remember-password-container {
        margin-top: 8%;
        display: flex;
        align-items: center;
        width: 80%;
        .login-right-remember-password-input {
          width: 16px;
          height: 16px;
          border-radius: 2px !important;
          border: none !important;
          outline: none !important;
          margin-right: 2%;
        }
        .login-right-remember-password {
          font-size: config.$font-content;
          color: config.$w-color;
        }
      }
      .login-right-btn {
        background-color: config.$w-color;
        width: 80%;
        margin-left: 2%;
        height: 6%;
        margin-top: 16%;
        border: none;
        border-radius: 16px;
        font-size: config.$font-subHeader;
        color: config.$primary-color;
        font-weight: 600;
        letter-spacing: 0.04em;
        opacity: 80%;
        cursor: pointer;
        &:hover {
          opacity: 100%;
        }
      }
    }
  }
}

