$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.config-toilet-del-icon {
  color: rgb(170, 170, 170);
  &:hover {
    color: rgb(92, 92, 92);
  }
}

@include Responsive(XL) {
  .config-change-date-service-container {
    margin-top: 2%;
    padding-left: 4%;
    padding-bottom: 3%;
    padding-top: 1%;
    border-left: 8px solid config.$primary-color;
    .ant-picker {
      width: 100% !important;
    }
  }

  .config-change-sen-word-container {
    margin-top: 2%;
    padding-left: 4%;
    padding-bottom: 3%;
    padding-top: 1%;
    border-left: 8px solid config.$primary-color;
    .ant-picker {
      width: 100% !important;
    }
  }

  .config-toilet-unit-container {
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    border-radius: 10px;
    margin-bottom: 40px;
    .config-header {
      p {
        font-size: 24px;
        font-weight: 600;
        color: config.$primary-color;
        font-style: normal;
        width: 82%;
        margin-bottom: 2%;
      }
      .config-add-new-tooltip {
        color: white;
        background-color: rgb(178, 178, 178);
        border-radius: 100%;
      }
    }
    .config-container {
      border-radius: 10px;
      border: 2px solid config.$secondary-color;
      padding: 0% 4%;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      // min-height: 490px;
      background-color: config.$w-color;
      .config-add-new {
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .config-infor-update {
          font-size: config.$font-content-large;
          font-weight: 400;
          font-style: italic;
          color: config.$primary-color;
          display: flex;
          justify-content: space-between;
          align-items: center;
          opacity: 70%;
          margin: 0;
        }
        .config-add-new-button-container {
          display: flex;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
      }
      .config-add-new-more-row {
        p {
          font-size: config.$font-header;
          font-weight: 600;
          color: config.$primary-color;
          width: 68%;
        }
        .config-add-new-tooltip {
          color: white;
          background-color: #f50;
          border-radius: 100%;
        }
        .config-add-new-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .config-tag-container {
      padding: 4%;
      border: 2px solid config.$secondary-color;
      margin-top: 4%;
      border-radius: 10px;
      min-height: 150px;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
      margin-bottom: 4%;
    }
  }

  .create-new-package-price-container-box {
    width: 30%;
    .create-new-package-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-package-price-input {
        width: 70%;
      }
    }
    .create-new-package-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }
  .create-new-service-price-container-box {
    width: 30%;
    .create-new-service-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-service-price-input {
        width: 70%;
      }
    }
    .create-new-service-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }
  .create-new-facility-price-container-box {
    width: 30%;
    .create-new-facility-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-facility-price-input {
        width: 70%;
      }
    }
    .create-new-facility-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
    .create-new-facility-price-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 70%;
    }
    .create-new-facility-price-text {
      margin-left: 4%;
    }
  }
  .create-new-discount-price-container-box {
    width: 40%;
    .create-new-discount-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2%;
      p {
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .create-new-discount-price-input {
        width: 12%;
        text-align: right;
      }
    }
    .create-new-discount-price-btn-container {
      margin-top: 4%;
      button:nth-child(2) {
        margin-left: 3%;
      }
    }
  }

  .config-package-price-container-box {
    .config-package-price-container {
      .config-package-price-table {
        margin-top: 8px;
        th {
          background-color: config.$w-color !important;
        }
        ul {
          justify-content: center;
          margin-top: 2% !important;
          .ant-pagination-total-text {
            position: absolute;
            left: 0;
          }
        }
        .ant-table-cell {
          background-color: config.$w-color !important;
        }
      }
    }
  }
  .config-service-price-container {
    .config-service-price-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }

  .config-discount-container {
    .config-discount-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}

@include Responsive(MD) {
  .create-new-package-price-container-box {
    width: 50%;
    .create-new-package-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-package-price-input {
        width: 70%;
      }
    }
    .create-new-package-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }

  .config-change-date-service-container {
    margin-top: 2%;
    padding-left: 4%;
    padding-bottom: 3%;
    padding-top: 1%;
    border-left: 8px solid config.$primary-color;
    .ant-picker {
      width: 100% !important;
    }
  }

  .config-toilet-unit-container {
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    border-radius: 10px;
    margin-bottom: 40px;
    .config-header {
      p {
        font-size: 24px;
        font-weight: 600;
        color: config.$primary-color;
        font-style: normal;
        width: 82%;
        margin-bottom: 2%;
      }
      .config-add-new-tooltip {
        color: white;
        background-color: rgb(178, 178, 178);
        border-radius: 100%;
      }
    }
    .config-container {
      border-radius: 10px;
      border: 2px solid config.$secondary-color;
      padding: 0% 4%;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      // min-height: 490px;
      background-color: config.$w-color;
      .config-add-new {
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .config-infor-update {
          font-size: config.$font-content-large;
          font-weight: 400;
          font-style: italic;
          color: config.$primary-color;
          display: flex;
          justify-content: space-between;
          align-items: center;
          opacity: 70%;
          margin: 0;
        }
        .config-add-new-button-container {
          display: flex;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
      }
      .config-add-new-more-row {
        p {
          font-size: config.$font-header;
          font-weight: 600;
          color: config.$primary-color;
          width: 68%;
        }
        .config-add-new-tooltip {
          color: white;
          background-color: #f50;
          border-radius: 100%;
        }
        .config-add-new-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .config-tag-container {
      padding: 4%;
      border: 2px solid config.$secondary-color;
      margin-top: 4%;
      border-radius: 10px;
      min-height: 150px;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
      margin-bottom: 4%;
    }
  }
  .create-new-service-price-container-box {
    width: 50%;
    .create-new-service-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-service-price-input {
        width: 70%;
      }
    }
    .create-new-service-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }
  .create-new-facility-price-container-box {
    width: 50%;
    .create-new-facility-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-facility-price-input {
        width: 70%;
      }
    }
    .create-new-facility-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
    .create-new-facility-price-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 70%;
    }
    .create-new-facility-price-text {
      margin-left: 4%;
    }
  }
  .create-new-discount-price-container-box {
    width: 40%;
    .create-new-discount-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2%;
      p {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .create-new-discount-price-input {
        width: 12%;
        text-align: right;
      }
    }
    .create-new-discount-price-btn-container {
      margin-top: 4%;
      button:nth-child(2) {
        margin-left: 3%;
      }
    }
  }
  .config-package-price-container-box {
    .config-package-price-container {
      .config-package-price-table {
        margin-top: 8px;
        th {
          background-color: config.$w-color !important;
        }
        ul {
          justify-content: center;
          margin-top: 2% !important;
          .ant-pagination-total-text {
            position: absolute;
            left: 0;
          }
        }
        .ant-table-cell {
          background-color: config.$w-color !important;
        }
      }
    }
  }
  .config-service-price-container {
    .config-service-price-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-facility-container {
    .config-facility-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-discount-container {
    .config-discount-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}
@include Responsive(SM) {
  .create-new-package-price-container-box {
    width: 80%;
    .create-new-package-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-package-price-input {
        width: 70%;
      }
    }
    .create-new-package-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }

  .config-change-date-service-container {
    margin-top: 2%;
    padding-left: 4%;
    padding-bottom: 3%;
    padding-top: 1%;
    border-left: 8px solid config.$primary-color;
    .ant-picker {
      width: 100% !important;
    }
  }

  .config-toilet-unit-container {
    margin-left: 40px;
    box-sizing: border-box;
    height: 98%;
    border-radius: 10px;
    margin-bottom: 40px;
    .config-header {
      p {
        font-size: 24px;
        font-weight: 600;
        color: config.$primary-color;
        font-style: normal;
        width: 82%;
        margin-bottom: 2%;
      }
      .config-add-new-tooltip {
        color: white;
        background-color: rgb(178, 178, 178);
        border-radius: 100%;
      }
    }
    .config-container {
      border-radius: 10px;
      border: 2px solid config.$secondary-color;
      padding: 0% 4%;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      // min-height: 490px;
      background-color: config.$w-color;
      .config-add-new {
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .config-infor-update {
          font-size: config.$font-content-large;
          font-weight: 400;
          font-style: italic;
          color: config.$primary-color;
          display: flex;
          justify-content: space-between;
          align-items: center;
          opacity: 70%;
          margin: 0;
        }
        .config-add-new-button-container {
          display: flex;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
      }
      .config-add-new-more-row {
        p {
          font-size: config.$font-header;
          font-weight: 600;
          color: config.$primary-color;
          width: 68%;
        }
        .config-add-new-tooltip {
          color: white;
          background-color: #f50;
          border-radius: 100%;
        }
        .config-add-new-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            &:first-child {
              margin-right: 4px;
            }
          }
        }
        margin-top: 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .config-tag-container {
      padding: 4%;
      border: 2px solid config.$secondary-color;
      margin-top: 4%;
      border-radius: 10px;
      min-height: 150px;
      box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
      background-color: config.$w-color;
      margin-bottom: 4%;
    }
  }
  
  .create-new-service-price-container-box {
    width: 80%;
    .create-new-service-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-service-price-input {
        width: 70%;
      }
    }
    .create-new-service-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }
  .create-new-facility-price-container-box {
    width: 80%;
    .create-new-facility-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-facility-price-input {
        width: 70%;
      }
    }
    .create-new-facility-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
    .create-new-facility-price-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 70%;
    }
    .create-new-facility-price-text {
      margin-left: 4%;
    }
  }
  .create-new-discount-price-container-box {
    width: 80%;
    .create-new-discount-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2%;
      p {
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .create-new-discount-price-input {
        width: 12%;
        text-align: right;
      }
    }
    .create-new-discount-price-btn-container {
      margin-top: 4%;
      button:nth-child(2) {
        margin-left: 3%;
      }
    }
  }
  .config-package-price-container-box {
    .config-package-price-container {
      .config-package-price-table {
        margin-top: 8px;
        th {
          background-color: config.$w-color !important;
        }
        ul {
          justify-content: center;
          margin-top: 2% !important;
          .ant-pagination-total-text {
            position: absolute;
            left: 0;
          }
        }
        .ant-table-cell {
          background-color: config.$w-color !important;
        }
      }
    }
  }
  .config-service-price-container {
    .config-service-price-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-facility-container {
    .config-facility-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-discount-container {
    .config-discount-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}
@include Responsive(XS) {
  .create-new-package-price-container-box {
    width: 100%;
    .create-new-package-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-package-price-input {
        width: 70%;
      }
    }
    .create-new-package-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }
  .create-new-service-price-container-box {
    width: 100%;
    .create-new-service-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-service-price-input {
        width: 70%;
      }
    }
    .create-new-service-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
  }

  .config-change-date-service-container {
    margin-top: 2%;
    padding-left: 4%;
    padding-bottom: 3%;
    padding-top: 1%;
    border-left: 8px solid config.$primary-color;
    .ant-picker {
      width: 100% !important;
    }
  }
  
  .create-new-facility-price-container-box {
    width: 100%;
    .create-new-facility-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4%;
      p {
        width: 30%;
      }
      .create-new-facility-price-input {
        width: 70%;
      }
    }
    .create-new-facility-price-btn-container {
      margin-top: 8%;
      margin-left: 30%;
      button:nth-child(2) {
        margin-left: 4%;
      }
    }
    .create-new-facility-price-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 70%;
    }
    .create-new-facility-price-text {
      margin-left: 4%;
    }
  }
  .create-new-discount-price-container-box {
    width: 100%;
    .create-new-discount-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2%;
      p {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .create-new-discount-price-input {
        width: 12%;
        text-align: right;
      }
    }
    .create-new-discount-price-btn-container {
      margin-top: 4%;
      button:nth-child(2) {
        margin-left: 3%;
      }
    }
  }
  .config-package-price-container-box {
    .config-package-price-container {
      .config-package-price-table {
        margin-top: 8px;
        th {
          background-color: config.$w-color !important;
        }
        ul {
          justify-content: center;
          margin-top: 2% !important;
          .ant-pagination-total-text {
            position: absolute;
            left: 0;
          }
        }
        .ant-table-cell {
          background-color: config.$w-color !important;
        }
      }
    }
  }
  .config-service-price-container {
    .config-service-price-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-facility-container {
    .config-facility-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
  .config-discount-container {
    .config-discount-table {
      margin-top: 8px;
      th {
        background-color: config.$w-color !important;
      }
      ul {
        justify-content: center;
        margin-top: 2% !important;
        .ant-pagination-total-text {
          position: absolute;
          left: 0;
        }
      }
      .ant-table-cell {
        background-color: config.$w-color !important;
      }
    }
  }
}
