@use "../../App.scss" as config;

$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@include Responsive(XL) {
  .breadcrumbs-container {
    height: auto;
    color: config.$primary-color;
    display: flex;
    align-items: center;
    font-size: config.$font-header;
    .breadcrumbs-icon {
      font-size: 24px;
      cursor: pointer;
    }
    .breadcrumbs-text {
      font-size: config.$font-header;
      margin-left: 16px;
      a {
        color: config.$primary-color;
        opacity: 40%;
      }
      span {
        color: config.$primary-color;
        font-weight: 600;
      }
      svg {
        color: config.$primary-color;
        opacity: 40%;
      }
    }
  }
}

@include Responsive(MD) {
  .breadcrumbs-container {
    height: auto;
    color: config.$primary-color;
    display: flex;
    align-items: center;
    font-size: config.$font-subHeader;
    .breadcrumbs-icon {
      font-size: 20px;
      cursor: pointer;
    }
    .breadcrumbs-text {
      font-size: config.$font-subHeader;
      margin-left: 16px;
      a {
        color: config.$primary-color;
        opacity: 40%;
      }
      span {
        color: config.$primary-color;
        font-weight: 600;
      }
      svg {
        color: config.$primary-color;
        opacity: 40%;
      }
    }
  }
}

@include Responsive(SM) {
  .breadcrumbs-container {
    height: auto;
    color: config.$primary-color;
    display: flex;
    align-items: center;
    font-size: config.$font-header;
    .breadcrumbs-icon {
      font-size: 20px;
      cursor: pointer;
    }
    .breadcrumbs-text {
      font-size: config.$font-subHeader;
      margin-left: 16px;
      a {
        color: config.$primary-color;
        opacity: 40%;
      }
      span {
        color: config.$primary-color;
        font-weight: 600;
      }
      svg {
        color: config.$primary-color;
        opacity: 40%;
      }
    }
  }
}

@include Responsive(XS) {
  .breadcrumbs-container {
    color: config.$primary-color;
    display: flex;
    align-items: center;
    font-size: config.$font-header;
    .breadcrumbs-icon {
      font-size: 20px;
      cursor: pointer;
    }
    .breadcrumbs-text {
      font-size: config.$font-subHeader;
      margin-left: 16px;
      font-size: 14px;
      a {
        color: config.$primary-color;
        opacity: 40%;
      }
      span {
        color: config.$primary-color;
        font-weight: 600;
      }
      svg {
        color: config.$primary-color;
        opacity: 40%;
      }
    }
  }
}
