$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../..//App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.recommend-toilet-content-excel {
    display: flex;
    align-items: center;
    button {
        margin-top: 2%;
    }
}