$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.tagHeader-container {
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
  background-color: config.$w-color;
  margin-bottom: 2%;
}

@include Responsive(XL) {
  .tagHeader-container {
    margin-left: 40px;
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    min-height: 94vh;
    .tagHeader-title {
      font-size: 26px;
      color: config.$primary-color;
      font-weight: 600;
      margin-bottom: 24px;
    }
    .ant-tabs-tab {
      font-size: 16px;
      opacity: 50%;
    }
    .ant-tabs-tab-active {
      opacity: 100%;
      font-weight: 500;
    }
  }
}

@include Responsive(MD) {
  .tagHeader-container {
    margin-left: 40px;
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    min-height: 94vh;
    .tagHeader-title {
      font-size: 24px;
      color: config.$primary-color;
      font-weight: 600;
      margin-bottom: 24px;
    }
    .ant-tabs-tab {
      font-size: 16px;
      opacity: 50%;
    }
    .ant-tabs-tab-active {
      opacity: 100%;
      font-weight: 500;
    }
  }
}

@include Responsive(SM) {
  .tagHeader-container {
    margin-left: 40px;
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    min-height: 94vh;
    .tagHeader-title {
      font-size: 24px;
      color: config.$primary-color;
      font-weight: 600;
      margin-bottom: 24px;
    }
    .ant-tabs-tab {
      font-size: 16px;
      opacity: 50%;
    }
    .ant-tabs-tab-active {
      opacity: 100%;
      font-weight: 500;
    }
  }
}

@include Responsive(XS) {
  .tagHeader-container {
    border: 1px solid config.$secondary-color;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    min-height: 94vh;
    .tagHeader-title {
      color: config.$primary-color;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .ant-tabs-tab {
      font-size: 16px;
      opacity: 50%;
      font-size: config.$font-content !important;
    }
    .ant-tabs-tab-active {
      opacity: 100%;
      font-weight: 500;
      font-size: config.$font-content !important;
    }
  }
}
