$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

.manage-advertisement-popover {
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: config.$primary-color;
  }
}

.create-new-ad-pic-upload-empty {
  margin-top: 8%;
  margin-left: 8%;
  background-color: config.$back-color;
  width: 80%;
  height: 140px;
  border: 1px dashed config.$black-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 60%;
  line-height: 2.4em;
  .create-new-ad-pic-upload-empty-icon {
    font-size: 24px;
  }
  p {
    font-weight: 600;
  }
}

.create-new-ad-pic-upload {
  margin-top: 8%;
  margin-left: 8%;
  background-color: config.$back-color;
  width: 80%;
  height: 140px;
  border: 1px dashed config.$black-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2.4em;
  object-fit: contain;
}

.create-new-ad-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@include Responsive(XL) {
  .manage-ads-details-container {
    display: flex;
    width: 100%;
    margin-top: 16px;
    .manage-ad-detail-img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 75%;
      margin-left: 32px;
      margin-right: 64px;
    }
    .manage-ad-detail-content {
      margin-bottom: 2%;
      p {
        &:first-child {
          opacity: 50%;
        }
        &:nth-child(2) {
          font-size: config.$font-subHeader;
          font-weight: 500;
        }
      }
    }
    .create-new-ad-content {
      margin-top: 4%;
      p {
        font-weight: 500;
      }
    }
  }
}

@include Responsive(MD) {
  .manage-ads-details-container {
    display: flex;
    width: 100%;
    margin-top: 16px;
    .manage-ad-detail-img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 75%;
      margin-left: 32px;
      margin-right: 64px;
    }
    .manage-ad-detail-content {
      margin-bottom: 2%;
      p {
        &:first-child {
          opacity: 50%;
        }
        &:nth-child(2) {
          font-size: config.$font-subHeader;
          font-weight: 500;
        }
      }
    }
    .create-new-ad-content {
      margin-top: 4%;
      p {
        font-weight: 500;
      }
    }
  }
}

@include Responsive(SM) {
  .manage-ads-details-container {
    display: flex;
    width: 100%;
    margin-top: 16px;
    .manage-ad-detail-img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 75%;
      margin-left: 32px;
      margin-right: 32px;
    }
    .manage-ad-detail-content {
      margin-bottom: 2%;
      p {
        &:first-child {
          opacity: 50%;
        }
        &:nth-child(2) {
          font-size: config.$font-subHeader;
          font-weight: 500;
        }
      }
    }
    .create-new-ad-content {
      margin-top: 4%;
      p {
        font-weight: 500;
      }
    }
  }
}

@include Responsive(XS) {
  .manage-ads-details-container {
    width: 100%;
    margin-top: 16px;
    .manage-ad-detail-img {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 75%;
      margin-left: 32px;
      margin-right: 64px;
    }
    .manage-ad-detail-content {
      margin-bottom: 2%;
      margin-left: 32px;
      margin-top: 16px;
      p {
        &:first-child {
          opacity: 50%;
        }
        &:nth-child(2) {
          font-size: config.$font-subHeader;
          font-weight: 500;
        }
      }
    }
    .create-new-ad-content {
      margin-top: 4%;
      p {
        font-weight: 500;
      }
    }
  }
}
