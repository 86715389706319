$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1440px;

@use "../../../App.scss" as config;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@include Responsive(XL) {
  .config-infor-admin-container {
    box-sizing: border-box;
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    height: 98%;
    border-radius: 10px;
    padding: 40px;
    .config-account-admin-container {
      margin-top: 1%;
      .config-account-admin {
        display: flex;
        flex-direction: row;
        line-height: 3em;
        p {
          &:first-child {
            opacity: 80%;
            width: 8em;
          }
          &:nth-child(2) {
            margin-left: 4em;
            font-weight: 500;
          }
        }
      }
      .config-account-admin-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50em;
        line-height: 3em;
        p {
          width: 16em;
        }
        .config-account-admin-input {
          width: 20em;
        }
      }
      .config-account-admin-btn-edit-container {
        display: flex;
        margin-left: 15em;
        margin-top: 1%;
      }
      .config-account-admin-btn-container {
        display: flex;
        .config-account-admin-btn {
          margin-top: 16px;
          margin-right: 8px;
        }
        .config-account-admin-save-btn {
          margin-top: 16px;
        }
      }
    }
  }
}

@include Responsive(MD) {
  .config-infor-admin-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    height: 88%;
    border-radius: 10px;
    padding: 40px;
    .config-account-admin-container {
      margin-top: 1%;
      .config-account-admin {
        display: flex;
        flex-direction: row;
        line-height: 3em;
        p {
          &:first-child {
            opacity: 80%;
            width: 8em;
          }
          &:nth-child(2) {
            margin-left: 4em;
            font-weight: 500;
          }
        }
      }
      .config-account-admin-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50em;
        line-height: 3em;
        p {
          width: 16em;
        }
        .config-account-admin-input {
          width: 20em;
        }
      }
      .config-account-admin-btn-edit-container {
        display: flex;
        margin-left: 15em;
        margin-top: 1%;
      }
      .config-account-admin-btn-container {
        display: flex;
        .config-account-admin-btn {
          margin-top: 16px;
          margin-right: 8px;
        }
        .config-account-admin-save-btn {
          margin-top: 16px;
        }
      }
    }
  }
}

@include Responsive(SM) {
  .config-infor-admin-container {
    border: 1px solid config.$secondary-color;
    margin-left: 40px;
    height: 88%;
    border-radius: 10px;
    padding: 40px;
    .config-account-admin-container {
      margin-top: 1%;
      .config-account-admin {
        display: flex;
        flex-direction: row;
        line-height: 3em;
        p {
          &:first-child {
            opacity: 80%;
            width: 8em;
          }
          &:nth-child(2) {
            margin-left: 4em;
            font-weight: 500;
          }
        }
      }
      .config-account-admin-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50em;
        line-height: 3em;
        p {
          width: 16em;
          font-size: config.$font-content;
        }
        .config-account-admin-input {
          width: 20em;
          font-size: config.$font-content;
        }
      }
      .config-account-admin-btn-edit-container {
        display: flex;
        margin-left: 13em;
        margin-top: 1%;
      }
      .config-account-admin-btn-container {
        display: flex;
        .config-account-admin-btn {
          margin-top: 16px;
          margin-right: 8px;
        }
        .config-account-admin-save-btn {
          margin-top: 16px;
        }
      }
    }
  }
}

@include Responsive(XS) {
  .config-infor-admin-container {
    border: 1px solid config.$secondary-color;
    height: 88%;
    border-radius: 10px;
    padding: 24px;
    .config-account-admin-container {
      margin-top: 2%;
      .config-account-admin {
        display: flex;
        flex-direction: row;
        line-height: 3em;
        p {
          &:first-child {
            opacity: 80%;
            font-size: config.$font-content;
            width: 8em;
          }
          &:nth-child(2) {
            margin-left: 4em;
            font-weight: 500;
          font-size: config.$font-content;

          }
        }
      }
      .config-account-admin-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        line-height: 3em;
        p {
          width: 16em;
          font-size: config.$font-content;
        }
        .config-account-admin-input {
          width: 16em;
        }
      }
      .config-account-admin-btn-edit-container {
        display: flex;
        margin-left: 13em;
        margin-top: 1%;
      }
      .config-account-admin-btn-container {
        display: flex;
        .config-account-admin-btn {
          margin-top: 16px;
          margin-right: 8px;
        }
        .config-account-admin-save-btn {
          margin-top: 16px;
        }
      }
    }
  }
}
